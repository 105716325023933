// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Modal$Pos from "../../resources/overlays/Modal.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Colors$Pos from "../../resources/theme/Colors.bs.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Scalar$Pos from "../../core/Scalar.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FuturePromise from "rescript-future/src/FuturePromise.bs.js";
import * as TableView$Pos from "../../resources/tables/TableView.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as Client from "@apollo/client";
import * as AsyncResult$Pos from "../../primitives/AsyncResult.bs.js";
import * as CartProduct$Pos from "../Cart/CartProduct.bs.js";
import * as DateHelpers$Pos from "../../helpers/DateHelpers.bs.js";
import * as Placeholder$Pos from "../../resources/layout-and-structure/Placeholder.bs.js";
import * as ApolloHelpers$Pos from "../../helpers/ApolloHelpers.bs.js";
import * as OrderEditForm$Pos from "./OrderEditForm.bs.js";
import * as AnimatedRender$Pos from "../../primitives/AnimatedRender.bs.js";
import * as ProductPriceTableCell$Pos from "../Product/ProductPriceTableCell.bs.js";
import * as ProductStockTableCell$Pos from "../Product/ProductStockTableCell.bs.js";
import * as ProductReferenceTableCell$Pos from "../Product/ProductReferenceTableCell.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = Client.gql(["query VariantsQuery($search: String, $after: String, $first: Int, $filterBy: InputVariantsQueryFilter)  {\nvariants(search: $search, after: $after, first: $first, filterBy: $filterBy)  {\n__typename  \npageInfo  {\n__typename  \nendCursor  \nhasNextPage  \n}\n\nedges  {\n__typename  \nnode  {\n__typename  \nid  \ncreatedAt  \nformattedName  \nformattedDescription  \nstockKeepingUnit  \npurchasedPrice  \nformattedPurchasedPrice  \npackaging  \nbulk  \ncapacityPrecision  \ncapacityUnit  \nstock  {\n__typename  \nrawQuantity  \nformattedQuantity  \nstate  \n}\n\nproduct  {\n__typename  \ntax  {\n__typename  \nvalue  \n}\n\n}\n\n}\n\n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.variants;
  var value$2 = value$1.pageInfo;
  var value$3 = value$2.endCursor;
  var value$4 = value$2.hasNextPage;
  var value$5 = value$1.edges;
  return {
          variants: {
            __typename: value$1.__typename,
            pageInfo: {
              __typename: value$2.__typename,
              endCursor: !(value$3 == null) ? value$3 : undefined,
              hasNextPage: !(value$4 == null) ? value$4 : undefined
            },
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.stockKeepingUnit;
                    var value$3 = value$1.purchasedPrice;
                    var value$4 = value$1.formattedPurchasedPrice;
                    var value$5 = value$1.packaging;
                    var value$6 = value$1.bulk;
                    var value$7 = value$1.capacityPrecision;
                    var value$8 = value$1.capacityUnit;
                    var value$9 = value$1.stock;
                    var value$10 = value$9.rawQuantity;
                    var value$11 = value$9.formattedQuantity;
                    var value$12 = value$9.state;
                    var tmp;
                    if (value$12 == null) {
                      tmp = undefined;
                    } else {
                      var tmp$1;
                      switch (value$12) {
                        case "ALERT" :
                            tmp$1 = "ALERT";
                            break;
                        case "DANGER" :
                            tmp$1 = "DANGER";
                            break;
                        case "OK" :
                            tmp$1 = "OK";
                            break;
                        default:
                          throw {
                                RE_EXN_ID: "Not_found",
                                Error: new Error()
                              };
                      }
                      tmp = tmp$1;
                    }
                    var value$13 = value$1.product;
                    var value$14 = value$13.tax;
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              id: value$1.id,
                              createdAt: Scalar$Pos.Datetime.parse(value$1.createdAt),
                              formattedName: value$1.formattedName,
                              formattedDescription: value$1.formattedDescription,
                              stockKeepingUnit: !(value$2 == null) ? value$2 : undefined,
                              purchasedPrice: !(value$3 == null) ? value$3 : undefined,
                              formattedPurchasedPrice: !(value$4 == null) ? value$4 : undefined,
                              packaging: !(value$5 == null) ? value$5 : undefined,
                              bulk: !(value$6 == null) ? value$6 : undefined,
                              capacityPrecision: !(value$7 == null) ? value$7 : undefined,
                              capacityUnit: !(value$8 == null) ? value$8 : undefined,
                              stock: {
                                __typename: value$9.__typename,
                                rawQuantity: !(value$10 == null) ? value$10 : undefined,
                                formattedQuantity: !(value$11 == null) ? value$11 : undefined,
                                state: tmp
                              },
                              product: {
                                __typename: value$13.__typename,
                                tax: {
                                  __typename: value$14.__typename,
                                  value: value$14.value
                                }
                              }
                            }
                          };
                  }), value$5)
          }
        };
}

function serialize(value) {
  var value$1 = value.variants;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.product;
          var value$3 = value$2.tax;
          var value$4 = value$3.value;
          var value$5 = value$3.__typename;
          var tax = {
            __typename: value$5,
            value: value$4
          };
          var value$6 = value$2.__typename;
          var product = {
            __typename: value$6,
            tax: tax
          };
          var value$7 = value$1.stock;
          var value$8 = value$7.state;
          var state = value$8 !== undefined ? (
              value$8 === "OK" ? "OK" : (
                  value$8 === "ALERT" ? "ALERT" : "DANGER"
                )
            ) : null;
          var value$9 = value$7.formattedQuantity;
          var formattedQuantity = value$9 !== undefined ? value$9 : null;
          var value$10 = value$7.rawQuantity;
          var rawQuantity = value$10 !== undefined ? value$10 : null;
          var value$11 = value$7.__typename;
          var stock = {
            __typename: value$11,
            rawQuantity: rawQuantity,
            formattedQuantity: formattedQuantity,
            state: state
          };
          var value$12 = value$1.capacityUnit;
          var capacityUnit = value$12 !== undefined ? value$12 : null;
          var value$13 = value$1.capacityPrecision;
          var capacityPrecision = value$13 !== undefined ? value$13 : null;
          var value$14 = value$1.bulk;
          var bulk = value$14 !== undefined ? value$14 : null;
          var value$15 = value$1.packaging;
          var packaging = value$15 !== undefined ? value$15 : null;
          var value$16 = value$1.formattedPurchasedPrice;
          var formattedPurchasedPrice = value$16 !== undefined ? value$16 : null;
          var value$17 = value$1.purchasedPrice;
          var purchasedPrice = value$17 !== undefined ? value$17 : null;
          var value$18 = value$1.stockKeepingUnit;
          var stockKeepingUnit = value$18 !== undefined ? value$18 : null;
          var value$19 = value$1.formattedDescription;
          var value$20 = value$1.formattedName;
          var value$21 = value$1.createdAt;
          var value$22 = Scalar$Pos.Datetime.serialize(value$21);
          var value$23 = value$1.id;
          var value$24 = value$1.__typename;
          var node = {
            __typename: value$24,
            id: value$23,
            createdAt: value$22,
            formattedName: value$20,
            formattedDescription: value$19,
            stockKeepingUnit: stockKeepingUnit,
            purchasedPrice: purchasedPrice,
            formattedPurchasedPrice: formattedPurchasedPrice,
            packaging: packaging,
            bulk: bulk,
            capacityPrecision: capacityPrecision,
            capacityUnit: capacityUnit,
            stock: stock,
            product: product
          };
          var value$25 = value.__typename;
          return {
                  __typename: value$25,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.pageInfo;
  var value$4 = value$3.hasNextPage;
  var hasNextPage = value$4 !== undefined ? value$4 : null;
  var value$5 = value$3.endCursor;
  var endCursor = value$5 !== undefined ? value$5 : null;
  var value$6 = value$3.__typename;
  var pageInfo = {
    __typename: value$6,
    endCursor: endCursor,
    hasNextPage: hasNextPage
  };
  var value$7 = value$1.__typename;
  var variants = {
    __typename: value$7,
    pageInfo: pageInfo,
    edges: edges
  };
  return {
          variants: variants
        };
}

function serializeInputObjectNullableStringEqualsFilter(inp) {
  var a = inp._equals;
  return {
          _equals: a !== undefined ? a : undefined
        };
}

function serializeInputObjectInFilter(inp) {
  var a = inp._in;
  return {
          _in: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function serializeInputObjectDateFilter(inp) {
  var a = inp._after;
  var a$1 = inp._before;
  var a$2 = inp._between;
  return {
          _after: a !== undefined ? Caml_option.valFromOption(a) : undefined,
          _before: a$1 !== undefined ? Caml_option.valFromOption(a$1) : undefined,
          _between: a$2 !== undefined ? Js_array.map((function (b) {
                    return b;
                  }), a$2) : undefined
        };
}

function serializeInputObjectIntegerRangeFilter(inp) {
  var a = inp._min;
  var a$1 = inp._max;
  var a$2 = inp._between;
  return {
          _min: a !== undefined ? a : undefined,
          _max: a$1 !== undefined ? a$1 : undefined,
          _between: a$2 !== undefined ? Js_array.map((function (b) {
                    return b;
                  }), a$2) : undefined
        };
}

function serializeInputObjectStringEqualsFilter(inp) {
  return {
          _equals: inp._equals
        };
}

function serializeInputObjectBooleanEqualsFilter(inp) {
  return {
          _equals: inp._equals
        };
}

function serializeInputObjectInputVariantsQueryFilter(inp) {
  var a = inp.shopIds;
  var a$1 = inp.stockKeepingUnit;
  var a$2 = inp.ean13;
  var a$3 = inp.active;
  var a$4 = inp.archived;
  var a$5 = inp.createdAt;
  var a$6 = inp.updatedAt;
  var a$7 = inp.categoryId;
  var a$8 = inp.supplierId;
  var a$9 = inp.stock;
  var a$10 = inp.producer;
  return {
          shopIds: a !== undefined ? serializeInputObjectInFilter(a) : undefined,
          stockKeepingUnit: a$1 !== undefined ? ({
                _equals: a$1._equals
              }) : undefined,
          ean13: a$2 !== undefined ? ({
                _equals: a$2._equals
              }) : undefined,
          active: a$3 !== undefined ? ({
                _equals: a$3._equals
              }) : undefined,
          archived: a$4 !== undefined ? (
              a$4 === "ONLY" ? "ONLY" : (
                  a$4 === "INCLUDED" ? "INCLUDED" : "EXCLUDED"
                )
            ) : undefined,
          createdAt: a$5 !== undefined ? serializeInputObjectDateFilter(a$5) : undefined,
          updatedAt: a$6 !== undefined ? serializeInputObjectDateFilter(a$6) : undefined,
          categoryId: a$7 !== undefined ? serializeInputObjectNullableStringEqualsFilter(a$7) : undefined,
          supplierId: a$8 !== undefined ? serializeInputObjectNullableStringEqualsFilter(a$8) : undefined,
          stock: a$9 !== undefined ? serializeInputObjectIntegerRangeFilter(a$9) : undefined,
          producer: a$10 !== undefined ? ({
                _equals: a$10._equals
              }) : undefined
        };
}

function serializeVariables(inp) {
  var a = inp.search;
  var a$1 = inp.after;
  var a$2 = inp.first;
  var a$3 = inp.filterBy;
  return {
          search: a !== undefined ? a : undefined,
          after: a$1 !== undefined ? a$1 : undefined,
          first: a$2 !== undefined ? a$2 : undefined,
          filterBy: a$3 !== undefined ? serializeInputObjectInputVariantsQueryFilter(a$3) : undefined
        };
}

function makeVariables(search, after, first, filterBy, param) {
  return {
          search: search,
          after: after,
          first: first,
          filterBy: filterBy
        };
}

function makeInputObjectInputVariantsQueryFilter(shopIds, stockKeepingUnit, ean13, active, archived, createdAt, updatedAt, categoryId, supplierId, stock, producer, param) {
  return {
          shopIds: shopIds,
          stockKeepingUnit: stockKeepingUnit,
          ean13: ean13,
          active: active,
          archived: archived,
          createdAt: createdAt,
          updatedAt: updatedAt,
          categoryId: categoryId,
          supplierId: supplierId,
          stock: stock,
          producer: producer
        };
}

function makeInputObjectInFilter(_in, param) {
  return {
          _in: _in
        };
}

function makeInputObjectStringEqualsFilter(_equals, param) {
  return {
          _equals: _equals
        };
}

function makeInputObjectBooleanEqualsFilter(_equals, param) {
  return {
          _equals: _equals
        };
}

function makeInputObjectDateFilter(_after, _before, _between, param) {
  return {
          _after: _after,
          _before: _before,
          _between: _between
        };
}

function makeInputObjectNullableStringEqualsFilter(_equals, param) {
  return {
          _equals: _equals
        };
}

function makeInputObjectIntegerRangeFilter(_min, _max, _between, param) {
  return {
          _min: _min,
          _max: _max,
          _between: _between
        };
}

function makeDefaultVariables(param) {
  return {
          search: undefined,
          after: undefined,
          first: undefined,
          filterBy: undefined
        };
}

var Query_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputVariantsQueryFilter: serializeInputObjectInputVariantsQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  serializeInputObjectStringEqualsFilter: serializeInputObjectStringEqualsFilter,
  serializeInputObjectBooleanEqualsFilter: serializeInputObjectBooleanEqualsFilter,
  serializeInputObjectDateFilter: serializeInputObjectDateFilter,
  serializeInputObjectNullableStringEqualsFilter: serializeInputObjectNullableStringEqualsFilter,
  serializeInputObjectIntegerRangeFilter: serializeInputObjectIntegerRangeFilter,
  makeVariables: makeVariables,
  makeInputObjectInputVariantsQueryFilter: makeInputObjectInputVariantsQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeInputObjectStringEqualsFilter: makeInputObjectStringEqualsFilter,
  makeInputObjectBooleanEqualsFilter: makeInputObjectBooleanEqualsFilter,
  makeInputObjectDateFilter: makeInputObjectDateFilter,
  makeInputObjectNullableStringEqualsFilter: makeInputObjectNullableStringEqualsFilter,
  makeInputObjectIntegerRangeFilter: makeInputObjectIntegerRangeFilter,
  makeDefaultVariables: makeDefaultVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var Query_refetchQueryDescription = include.refetchQueryDescription;

var Query_useLazy = include.useLazy;

var Query_useLazyWithVariables = include.useLazyWithVariables;

var Query = {
  Query_inner: Query_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputVariantsQueryFilter: serializeInputObjectInputVariantsQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  serializeInputObjectStringEqualsFilter: serializeInputObjectStringEqualsFilter,
  serializeInputObjectBooleanEqualsFilter: serializeInputObjectBooleanEqualsFilter,
  serializeInputObjectDateFilter: serializeInputObjectDateFilter,
  serializeInputObjectNullableStringEqualsFilter: serializeInputObjectNullableStringEqualsFilter,
  serializeInputObjectIntegerRangeFilter: serializeInputObjectIntegerRangeFilter,
  makeVariables: makeVariables,
  makeInputObjectInputVariantsQueryFilter: makeInputObjectInputVariantsQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeInputObjectStringEqualsFilter: makeInputObjectStringEqualsFilter,
  makeInputObjectBooleanEqualsFilter: makeInputObjectBooleanEqualsFilter,
  makeInputObjectDateFilter: makeInputObjectDateFilter,
  makeInputObjectNullableStringEqualsFilter: makeInputObjectNullableStringEqualsFilter,
  makeInputObjectIntegerRangeFilter: makeInputObjectIntegerRangeFilter,
  makeDefaultVariables: makeDefaultVariables,
  refetchQueryDescription: Query_refetchQueryDescription,
  use: use,
  useLazy: Query_useLazy,
  useLazyWithVariables: Query_useLazyWithVariables
};

function rowsFromEdgesData(data) {
  return Belt_Array.map(data, (function (edge) {
                var description = edge.node.formattedDescription;
                var tmp = description === "" ? Intl$Pos.t("Description not filled in") : description;
                var match = edge.node;
                var match$1 = match.bulk;
                var tmp$1;
                if (match$1 !== undefined && match$1) {
                  var precision = match.capacityPrecision;
                  tmp$1 = precision !== undefined ? precision : undefined;
                } else {
                  tmp$1 = undefined;
                }
                return {
                        id: edge.node.id,
                        createdAt: edge.node.createdAt,
                        stockKeepingUnit: edge.node.stockKeepingUnit,
                        name: edge.node.formattedName,
                        description: tmp,
                        purchasedPrice: Belt_Option.getWithDefault(edge.node.purchasedPrice, 0),
                        formattedPurchasedPrice: edge.node.formattedPurchasedPrice,
                        packaging: edge.node.packaging,
                        bulkPrecision: tmp$1,
                        capacityUnit: edge.node.capacityUnit,
                        tax: edge.node.product.tax.value,
                        stockQuantity: edge.node.stock.rawQuantity,
                        stockFormattedQuantity: edge.node.stock.formattedQuantity,
                        stockState: edge.node.stock.state
                      };
              }));
}

var columns = [
  {
    key: "reference",
    name: Intl$Pos.t("Product"),
    layout: {
      width: {
        NAME: "pct",
        VAL: 65
      }
    },
    render: (function (param) {
        var product = param.data;
        var badgeNew = DateHelpers$Pos.diffInHours(new Date(), product.createdAt) <= 24 ? ({
              variation: "information",
              text: Intl$Pos.t("New")
            }) : undefined;
        var tmp = {
          variation: "secondary",
          name: product.name,
          description: product.description,
          disabled: param.disabled,
          openNewTab: true
        };
        if (badgeNew !== undefined) {
          tmp.badge = Caml_option.valFromOption(badgeNew);
        }
        if (product.stockKeepingUnit !== undefined) {
          tmp.stockKeepingUnit = product.stockKeepingUnit;
        }
        return React.createElement(ProductReferenceTableCell$Pos.make, tmp);
      })
  },
  {
    key: "stock",
    name: Intl$Pos.t("Stock"),
    render: (function (param) {
        var product = param.data;
        if (param.disabled) {
          return React.createElement(TextStyle$Pos.make, {
                      children: Intl$Pos.t("Already added"),
                      variation: "subdued",
                      size: "small"
                    });
        }
        var tmp = {
          value: product.stockFormattedQuantity,
          size: "xsmall"
        };
        if (product.stockState !== undefined) {
          tmp.state = Caml_option.valFromOption(product.stockState);
        }
        return React.createElement(ProductStockTableCell$Pos.make, tmp);
      })
  },
  {
    key: "purchasedPrice",
    name: Intl$Pos.t("Purchase price"),
    layout: {
      width: {
        NAME: "px",
        VAL: 120
      }
    },
    render: (function (param) {
        if (param.disabled) {
          return null;
        } else {
          return React.createElement(ProductPriceTableCell$Pos.make, {
                      value: param.data.formattedPurchasedPrice,
                      size: "xsmall"
                    });
        }
      })
  }
];

function setDefaultOrderedQuantityValue(productInput) {
  if (productInput.TAG === /* Unit */0) {
    var product = productInput.product;
    return {
            TAG: /* Unit */0,
            product: {
              id: product.id,
              identifier: product.identifier,
              stockKeepingUnit: product.stockKeepingUnit,
              name: product.name,
              description: product.description,
              capacityUnit: product.capacityUnit,
              stock: product.stock,
              packaging: product.packaging,
              quantity: 1,
              expectedQuantity: product.expectedQuantity,
              unitPrice: product.unitPrice,
              fees: product.fees,
              discounts: product.discounts,
              tax: product.tax
            }
          };
  }
  var product$1 = productInput.product;
  return {
          TAG: /* Bulk */1,
          product: {
            id: product$1.id,
            identifier: product$1.identifier,
            stockKeepingUnit: product$1.stockKeepingUnit,
            name: product$1.name,
            description: product$1.description,
            capacityUnit: product$1.capacityUnit,
            stock: product$1.stock,
            packaging: product$1.packaging,
            quantity: 1,
            expectedQuantity: product$1.expectedQuantity,
            unitPrice: product$1.unitPrice,
            fees: product$1.fees,
            discounts: product$1.discounts,
            tax: product$1.tax
          },
          precision: productInput.precision
        };
}

var make = React.memo(function (Props) {
      var children = Props.children;
      var opened = Props.opened;
      var selectedProducts = Props.selectedProducts;
      var onCommit = Props.onCommit;
      var onRequestClose = Props.onRequestClose;
      var renderStartText = function (param) {
        var length = selectedProducts.length;
        var shouldBePrimaryView = length > 0;
        var formattedText = "" + String(length) + " " + Intl$Pos.t(Intl$Pos.isPlural(length) ? "selected products" : "selected product");
        return React.createElement(Inline$Pos.make, {
                    children: React.createElement(TextStyle$Pos.make, {
                          children: formattedText,
                          variation: shouldBePrimaryView ? "primary" : "neutral",
                          weight: shouldBePrimaryView ? "strong" : "regular"
                        })
                  });
      };
      var onCommit$1 = function (param) {
        Curry._1(onCommit, Belt_Array.map(selectedProducts, (function (row) {
                    return setDefaultOrderedQuantityValue(CartProduct$Pos.makeProductInput(row.id, row.name, row.description, row.stockKeepingUnit, row.stockQuantity, row.packaging, row.tax, row.purchasedPrice, undefined, row.bulkPrecision, row.capacityUnit, undefined));
                  })));
      };
      return React.createElement(Modal$Pos.make, {
                  children: children,
                  opened: opened,
                  title: Intl$Pos.t("Product append"),
                  variation: "secondary",
                  backgroundColor: Colors$Pos.neutralColor00,
                  renderStartText: renderStartText,
                  abortButtonText: Intl$Pos.t("Cancel"),
                  allowCommit: selectedProducts.length !== 0,
                  commitButtonVariation: "primary",
                  commitButtonText: Intl$Pos.t("Add products"),
                  commitButtonCallback: onCommit$1,
                  onRequestClose: onRequestClose
                });
    });

var PickerModal = {
  make: make
};

function makeVariables$1(search, shopId, supplierId, after, param) {
  return makeVariables(search, after, 10, makeInputObjectInputVariantsQueryFilter({
                  _in: [shopId]
                }, undefined, undefined, undefined, undefined, undefined, undefined, undefined, supplierId !== undefined ? ({
                      _equals: supplierId
                    }) : undefined, undefined, undefined, undefined), undefined);
}

function runScanEdges(fetcher, cursor, dataOpt, param) {
  var data = dataOpt !== undefined ? dataOpt : [];
  return Future.flatMapOk(Future.mapError(FuturePromise.fromPromise(Curry._1(fetcher, cursor)), undefined, (function (param) {
                    
                  })), undefined, (function (response) {
                if (response.TAG !== /* Ok */0) {
                  return Future.value({
                              TAG: /* Error */1,
                              _0: undefined
                            });
                }
                var match = response._0;
                var match$1 = match.data.variants;
                var match$2 = match$1.pageInfo;
                var match$3 = match$2.hasNextPage;
                if (match$3 !== undefined && match$3) {
                  if (match.error !== undefined) {
                    return Future.value({
                                TAG: /* Error */1,
                                _0: undefined
                              });
                  } else {
                    return runScanEdges(fetcher, match$2.endCursor, Belt_Array.concat(data, match$1.edges), undefined);
                  }
                }
                if (match.error !== undefined) {
                  return Future.value({
                              TAG: /* Error */1,
                              _0: undefined
                            });
                } else {
                  return Future.value({
                              TAG: /* Ok */0,
                              _0: Belt_Array.concat(data, match$1.edges)
                            });
                }
              }));
}

function OrderProductFormPickerModalTable(Props) {
  var opened = Props.opened;
  var shopId = Props.shopId;
  var supplierId = Props.supplierId;
  var supplierNameOpt = Props.supplierName;
  var disabledIdsOpt = Props.disabledIds;
  var onCommit = Props.onCommit;
  var onRequestClose = Props.onRequestClose;
  var supplierName = supplierNameOpt !== undefined ? supplierNameOpt : "?";
  var disabledIds = disabledIdsOpt !== undefined ? disabledIdsOpt : [];
  var supplierId$1 = supplierId !== undefined && supplierId !== "" ? supplierId : undefined;
  var match = React.useState(function () {
        return [];
      });
  var setSelectedProducts = match[1];
  var match$1 = React.useState(function () {
        return "";
      });
  var setSearchQuery = match$1[1];
  var searchQuery = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setFilteredBySupplier = match$2[1];
  var filteredBySupplier = match$2[0];
  var supplierIdVariable = Belt_Option.isSome(supplierId$1) && filteredBySupplier ? supplierId$1 : undefined;
  var query = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        /* NetworkOnly */3,
        /* CacheFirst */1,
        undefined,
        true,
        undefined,
        undefined,
        undefined,
        undefined,
        shopId === "",
        undefined,
        makeVariables$1(searchQuery, shopId, supplierIdVariable, undefined, undefined)
      ]);
  var asyncResult = ApolloHelpers$Pos.useQueryResultToAsyncResultWithVariablesReloading3(query, searchQuery, shopId, supplierId$1);
  var data = AsyncResult$Pos.mapOk(asyncResult, (function (data) {
          return rowsFromEdgesData(data.variants.edges);
        }));
  React.useEffect((function () {
          if (!opened && searchQuery !== "") {
            Curry._1(setSearchQuery, (function (param) {
                    return "";
                  }));
          }
          
        }), [opened]);
  React.useEffect((function () {
          Curry._1(setFilteredBySupplier, (function (param) {
                  return Belt_Option.isSome(supplierId$1);
                }));
        }), [supplierId$1]);
  var onLoadMore = React.useCallback((function (param) {
          if (typeof asyncResult === "number") {
            return ;
          }
          if (asyncResult.TAG !== /* Done */1) {
            return ;
          }
          var data = asyncResult._0;
          if (data.TAG !== /* Ok */0) {
            return ;
          }
          var data$1 = data._0;
          if (data$1.variants.pageInfo.hasNextPage === true) {
            Curry._5(query.fetchMore, undefined, undefined, makeVariables$1(searchQuery, shopId, supplierIdVariable, data$1.variants.pageInfo.endCursor, undefined), (function (prevResult, param) {
                    var fetchMoreResult = param.fetchMoreResult;
                    if (fetchMoreResult === undefined) {
                      return prevResult;
                    }
                    var newVariants = fetchMoreResult.variants;
                    return {
                            variants: {
                              __typename: newVariants.__typename,
                              pageInfo: newVariants.pageInfo,
                              edges: Belt_Array.concat(prevResult.variants.edges, newVariants.edges)
                            }
                          };
                  }), undefined);
            return ;
          }
          
        }), [asyncResult]);
  var onSelectChange = React.useCallback((function (selection) {
          Curry._1(setSelectedProducts, (function (prev) {
                  var rowsData;
                  if (typeof data === "number") {
                    rowsData = data === /* NotAsked */0 ? [] : [];
                  } else if (data.TAG === /* Reloading */0) {
                    var data$1 = data._0;
                    rowsData = data$1.TAG === /* Ok */0 ? data$1._0 : [];
                  } else {
                    var data$2 = data._0;
                    rowsData = data$2.TAG === /* Ok */0 ? data$2._0 : [];
                  }
                  if (selection) {
                    return Belt_Array.keepMap(selection._0, (function (selectedRowId) {
                                  return Belt_Array.getBy(Belt_Array.concat(rowsData, prev), (function (row) {
                                                return row.id === selectedRowId;
                                              }));
                                }));
                  } else {
                    return prev;
                  }
                }));
        }), [
        data,
        disabledIds
      ]);
  var noneItem_label = Intl$Pos.t("All");
  var noneItem = {
    key: "default",
    label: noneItem_label
  };
  var supplierItem = {
    key: "supplier",
    value: supplierId$1,
    label: supplierName
  };
  var filters = [/* Select */{
      _0: {
        label: Intl$Pos.t("Supplier"),
        disabledMessage: Belt_Option.isNone(supplierId$1) ? Intl$Pos.t("Please select a supplier linked to the order.") : undefined,
        items: Belt_Option.isSome(supplierId$1) ? [
            noneItem,
            supplierItem
          ] : [noneItem],
        value: filteredBySupplier ? supplierId$1 : undefined,
        onChange: (function (value) {
            Curry._1(setFilteredBySupplier, (function (param) {
                    return Belt_Option.isSome(value);
                  }));
          })
      }
    }];
  var placeholderEmptyState = React.createElement(Placeholder$Pos.make, {
        status: shopId !== "" ? /* NoDataAvailable */1 : /* Pending */({
              title: Intl$Pos.t("Beware !"),
              message: Intl$Pos.t("Select the shop in charge of the order to start adding products."),
              illustration: undefined
            }),
        childComponent: (function (param) {
            return React.createElement(OrderEditForm$Pos.Select.make, {
                        field: /* ShopId */7,
                        label: Intl$Pos.t("Shop"),
                        placeholder: Intl$Pos.t("Select a shop"),
                        sections: [{
                            items: Belt_Array.map(Auth$Pos.useShops(undefined), (function (shop) {
                                    return {
                                            key: shop.id,
                                            value: shop.id,
                                            label: shop.name
                                          };
                                  }))
                          }]
                      });
          })
      });
  return React.createElement(make, {
              children: React.createElement(AnimatedRender$Pos.make, {
                    children: React.createElement(TableView$Pos.make, {
                          data: data,
                          columns: columns,
                          keyExtractor: (function (row) {
                              return row.id;
                            }),
                          disabledRowsKeys: disabledIds,
                          selectionEnabled: true,
                          selectAllEnabled: false,
                          hideReloadingPlaceholder: true,
                          maxWidth: 580,
                          minHeight: 315,
                          maxHeight: 315,
                          placeholderEmptyState: placeholderEmptyState,
                          searchPlaceholder: Intl$Pos.t("Search product"),
                          filters: filters,
                          onSearchQueryChange: (function (value) {
                              Curry._1(setSearchQuery, (function (param) {
                                      return value;
                                    }));
                            }),
                          onLoadMore: onLoadMore,
                          onSelectChange: onSelectChange
                        }),
                    displayed: opened,
                    animation: "fadePopinTranslation",
                    duration: 300
                  }),
              opened: opened,
              selectedProducts: match[0],
              onCommit: onCommit,
              onRequestClose: onRequestClose
            });
}

var make$1 = React.memo(OrderProductFormPickerModalTable, (function (oldProps, newProps) {
        if (oldProps.opened === newProps.opened && oldProps.shopId === newProps.shopId) {
          return oldProps.supplierId === newProps.supplierId;
        } else {
          return false;
        }
      }));

export {
  Query ,
  rowsFromEdgesData ,
  columns ,
  setDefaultOrderedQuantityValue ,
  PickerModal ,
  makeVariables$1 as makeVariables,
  runScanEdges ,
  make$1 as make,
}
/* query Not a pure module */
