// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Env$Pos from "../../core/Env.bs.js";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Json$Pos from "../../primitives/Json.bs.js";
import * as Menu$Pos from "../../resources/navigation/Menu.bs.js";
import * as Banner$Pos from "../../resources/feedback-indicators/Banner.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Button$Pos from "../../resources/actions/Button.bs.js";
import * as Device$Pos from "../../modules/Device.bs.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Scalar$Pos from "../../core/Scalar.bs.js";
import * as Select$Pos from "../../resources/selection-and-input/Select.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Popover$Pos from "../../resources/overlays/Popover.bs.js";
import * as Request$Pos from "../../core/Request.bs.js";
import * as Scaffold$Pos from "../../bundles/Scaffold/Scaffold.bs.js";
import * as JsonCodec$Pos from "../../primitives/JsonCodec.bs.js";
import * as SearchBar$Pos from "../../resources/selection-and-input/SearchBar.bs.js";
import * as Separator$Pos from "../../resources/layout-and-structure/Separator.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as Client from "@apollo/client";
import * as EmptyState$Pos from "../../resources/layout-and-structure/EmptyState.bs.js";
import * as Illustration$Pos from "../../resources/images-and-icons/Illustration.bs.js";
import * as CatalogProduct$Pos from "../../modules/Catalog/CatalogProduct.bs.js";
import * as SelectDateRange$Pos from "../../resources/selection-and-input/SelectDateRange.bs.js";
import * as StockActivityKind$Pos from "../../modules/StockActivity/StockActivityKind.bs.js";
import * as TableDatetimeCell$Pos from "../../resources/tables/TableDatetimeCell.bs.js";
import * as StockActivityReason$Pos from "../../modules/StockActivity/StockActivityReason.bs.js";
import * as ProductReferenceTableCell$Pos from "../../modules/Product/ProductReferenceTableCell.bs.js";
import * as StockActivityTypeTableCell$Pos from "../../modules/StockActivity/StockActivityTypeTableCell.bs.js";
import * as StockActivitySourceTableCell$Pos from "../../modules/StockActivity/StockActivitySourceTableCell.bs.js";
import * as RequestOpenStorageUrlMenuItem$Pos from "../../resources/actions/RequestOpenStorageUrlMenuItem.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";
import * as StockActivityQuantityTableCell$Pos from "../../modules/StockActivity/StockActivityQuantityTableCell.bs.js";

var Raw = {};

var query = Client.gql(["query StockActivityPageQuery($search: String, $before: String, $after: String, $first: Int, $last: Int, $filterBy: InputStockActivitiesQueryFilter)  {\nstockActivities(search: $search, before: $before, after: $after, first: $first, last: $last, filterBy: $filterBy)  {\n__typename  \npageInfo  {\n__typename  \nstartCursor  \nendCursor  \n}\n\nedges  {\n__typename  \nnode  {\n__typename  \nid  \nvariant  {\n__typename  \nid  \ncku  \nformattedName  \nformattedDescription  \nstockKeepingUnit  \ncapacityPrecision  \ncapacityUnit  \nbulk  \nproduct  {\n__typename  \ncolor  \n}\n\n}\n\nkind  \nreason  \ncomment  \nquantity  \ncreatedAt  \nshop  {\n__typename  \nname  \n}\n\ndevice  {\n__typename  \nname  \nslug  \n}\n\n}\n\n}\n\ntotalCount  \n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.stockActivities;
  var value$2 = value$1.pageInfo;
  var value$3 = value$2.startCursor;
  var value$4 = value$2.endCursor;
  var value$5 = value$1.edges;
  return {
          stockActivities: {
            __typename: value$1.__typename,
            pageInfo: {
              __typename: value$2.__typename,
              startCursor: !(value$3 == null) ? value$3 : undefined,
              endCursor: !(value$4 == null) ? value$4 : undefined
            },
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.variant;
                    var tmp;
                    if (value$2 == null) {
                      tmp = undefined;
                    } else {
                      var value$3 = value$2.stockKeepingUnit;
                      var value$4 = value$2.capacityPrecision;
                      var value$5 = value$2.capacityUnit;
                      var value$6 = value$2.bulk;
                      var value$7 = value$2.product;
                      var value$8 = value$7.color;
                      var tmp$1;
                      if (value$8 == null) {
                        tmp$1 = undefined;
                      } else {
                        var tmp$2;
                        switch (value$8) {
                          case "AMBER" :
                              tmp$2 = "AMBER";
                              break;
                          case "BLACK" :
                              tmp$2 = "BLACK";
                              break;
                          case "BLOND" :
                              tmp$2 = "BLOND";
                              break;
                          case "DARK" :
                              tmp$2 = "DARK";
                              break;
                          case "ORANGE" :
                              tmp$2 = "ORANGE";
                              break;
                          case "RED" :
                              tmp$2 = "RED";
                              break;
                          case "ROSE" :
                              tmp$2 = "ROSE";
                              break;
                          case "WHITE" :
                              tmp$2 = "WHITE";
                              break;
                          default:
                            throw {
                                  RE_EXN_ID: "Not_found",
                                  Error: new Error()
                                };
                        }
                        tmp$1 = tmp$2;
                      }
                      tmp = {
                        __typename: value$2.__typename,
                        id: value$2.id,
                        cku: Scalar$Pos.CKU.parse(value$2.cku),
                        formattedName: value$2.formattedName,
                        formattedDescription: value$2.formattedDescription,
                        stockKeepingUnit: !(value$3 == null) ? value$3 : undefined,
                        capacityPrecision: !(value$4 == null) ? value$4 : undefined,
                        capacityUnit: !(value$5 == null) ? value$5 : undefined,
                        bulk: !(value$6 == null) ? value$6 : undefined,
                        product: {
                          __typename: value$7.__typename,
                          color: tmp$1
                        }
                      };
                    }
                    var value$9 = value$1.kind;
                    var tmp$3;
                    switch (value$9) {
                      case "CREDIT_MEMO" :
                          tmp$3 = "CREDIT_MEMO";
                          break;
                      case "DELIVERY" :
                          tmp$3 = "DELIVERY";
                          break;
                      case "DELIVERY_RECEIPT" :
                          tmp$3 = "DELIVERY_RECEIPT";
                          break;
                      case "INCOMING_TRANSFER" :
                          tmp$3 = "INCOMING_TRANSFER";
                          break;
                      case "LOSS" :
                          tmp$3 = "LOSS";
                          break;
                      case "OUTGOING_TRANSFER" :
                          tmp$3 = "OUTGOING_TRANSFER";
                          break;
                      case "RECEPTION" :
                          tmp$3 = "RECEPTION";
                          break;
                      case "REFUND" :
                          tmp$3 = "REFUND";
                          break;
                      case "RESET" :
                          tmp$3 = "RESET";
                          break;
                      case "SALE" :
                          tmp$3 = "SALE";
                          break;
                      default:
                        throw {
                              RE_EXN_ID: "Not_found",
                              Error: new Error()
                            };
                    }
                    var value$10 = value$1.reason;
                    var tmp$4;
                    if (value$10 == null) {
                      tmp$4 = undefined;
                    } else {
                      var tmp$5;
                      switch (value$10) {
                        case "CORKY" :
                            tmp$5 = "CORKY";
                            break;
                        case "DAMAGE" :
                            tmp$5 = "DAMAGE";
                            break;
                        case "LOSS" :
                            tmp$5 = "LOSS";
                            break;
                        case "OTHER" :
                            tmp$5 = "OTHER";
                            break;
                        case "OUTDATED_DLUO" :
                            tmp$5 = "OUTDATED_DLUO";
                            break;
                        case "SALE_BY_GLASS" :
                            tmp$5 = "SALE_BY_GLASS";
                            break;
                        case "TASTING" :
                            tmp$5 = "TASTING";
                            break;
                        case "THEFT" :
                            tmp$5 = "THEFT";
                            break;
                        case "TRADE_IN_SUPPLIER" :
                            tmp$5 = "TRADE_IN_SUPPLIER";
                            break;
                        default:
                          throw {
                                RE_EXN_ID: "Not_found",
                                Error: new Error()
                              };
                      }
                      tmp$4 = tmp$5;
                    }
                    var value$11 = value$1.comment;
                    var value$12 = value$1.shop;
                    var value$13 = value$1.device;
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              id: value$1.id,
                              variant: tmp,
                              kind: tmp$3,
                              reason: tmp$4,
                              comment: !(value$11 == null) ? Scalar$Pos.$$Text.parse(value$11) : undefined,
                              quantity: value$1.quantity,
                              createdAt: Scalar$Pos.Datetime.parse(value$1.createdAt),
                              shop: {
                                __typename: value$12.__typename,
                                name: value$12.name
                              },
                              device: {
                                __typename: value$13.__typename,
                                name: value$13.name,
                                slug: value$13.slug
                              }
                            }
                          };
                  }), value$5),
            totalCount: value$1.totalCount
          }
        };
}

function serialize(value) {
  var value$1 = value.stockActivities;
  var value$2 = value$1.totalCount;
  var value$3 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.device;
          var value$3 = value$2.slug;
          var value$4 = value$2.name;
          var value$5 = value$2.__typename;
          var device = {
            __typename: value$5,
            name: value$4,
            slug: value$3
          };
          var value$6 = value$1.shop;
          var value$7 = value$6.name;
          var value$8 = value$6.__typename;
          var shop = {
            __typename: value$8,
            name: value$7
          };
          var value$9 = value$1.createdAt;
          var value$10 = Scalar$Pos.Datetime.serialize(value$9);
          var value$11 = value$1.quantity;
          var value$12 = value$1.comment;
          var comment = value$12 !== undefined ? Scalar$Pos.$$Text.serialize(value$12) : null;
          var value$13 = value$1.reason;
          var reason = value$13 !== undefined ? (
              value$13 === "DAMAGE" ? "DAMAGE" : (
                  value$13 === "OUTDATED_DLUO" ? "OUTDATED_DLUO" : (
                      value$13 === "SALE_BY_GLASS" ? "SALE_BY_GLASS" : (
                          value$13 === "THEFT" ? "THEFT" : (
                              value$13 === "TASTING" ? "TASTING" : (
                                  value$13 === "LOSS" ? "LOSS" : (
                                      value$13 === "OTHER" ? "OTHER" : (
                                          value$13 === "TRADE_IN_SUPPLIER" ? "TRADE_IN_SUPPLIER" : "CORKY"
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ) : null;
          var value$14 = value$1.kind;
          var kind = value$14 === "DELIVERY_RECEIPT" ? "DELIVERY_RECEIPT" : (
              value$14 === "RESET" ? "RESET" : (
                  value$14 === "INCOMING_TRANSFER" ? "INCOMING_TRANSFER" : (
                      value$14 === "DELIVERY" ? "DELIVERY" : (
                          value$14 === "REFUND" ? "REFUND" : (
                              value$14 === "LOSS" ? "LOSS" : (
                                  value$14 === "CREDIT_MEMO" ? "CREDIT_MEMO" : (
                                      value$14 === "SALE" ? "SALE" : (
                                          value$14 === "RECEPTION" ? "RECEPTION" : "OUTGOING_TRANSFER"
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            );
          var value$15 = value$1.variant;
          var variant;
          if (value$15 !== undefined) {
            var value$16 = value$15.product;
            var value$17 = value$16.color;
            var color = value$17 !== undefined ? (
                value$17 === "ORANGE" ? "ORANGE" : (
                    value$17 === "RED" ? "RED" : (
                        value$17 === "AMBER" ? "AMBER" : (
                            value$17 === "DARK" ? "DARK" : (
                                value$17 === "BLACK" ? "BLACK" : (
                                    value$17 === "BLOND" ? "BLOND" : (
                                        value$17 === "ROSE" ? "ROSE" : "WHITE"
                                      )
                                  )
                              )
                          )
                      )
                  )
              ) : null;
            var value$18 = value$16.__typename;
            var product = {
              __typename: value$18,
              color: color
            };
            var value$19 = value$15.bulk;
            var bulk = value$19 !== undefined ? value$19 : null;
            var value$20 = value$15.capacityUnit;
            var capacityUnit = value$20 !== undefined ? value$20 : null;
            var value$21 = value$15.capacityPrecision;
            var capacityPrecision = value$21 !== undefined ? value$21 : null;
            var value$22 = value$15.stockKeepingUnit;
            var stockKeepingUnit = value$22 !== undefined ? value$22 : null;
            var value$23 = value$15.formattedDescription;
            var value$24 = value$15.formattedName;
            var value$25 = value$15.cku;
            var value$26 = Scalar$Pos.CKU.serialize(value$25);
            var value$27 = value$15.id;
            var value$28 = value$15.__typename;
            variant = {
              __typename: value$28,
              id: value$27,
              cku: value$26,
              formattedName: value$24,
              formattedDescription: value$23,
              stockKeepingUnit: stockKeepingUnit,
              capacityPrecision: capacityPrecision,
              capacityUnit: capacityUnit,
              bulk: bulk,
              product: product
            };
          } else {
            variant = null;
          }
          var value$29 = value$1.id;
          var value$30 = value$1.__typename;
          var node = {
            __typename: value$30,
            id: value$29,
            variant: variant,
            kind: kind,
            reason: reason,
            comment: comment,
            quantity: value$11,
            createdAt: value$10,
            shop: shop,
            device: device
          };
          var value$31 = value.__typename;
          return {
                  __typename: value$31,
                  node: node
                };
        }), value$3);
  var value$4 = value$1.pageInfo;
  var value$5 = value$4.endCursor;
  var endCursor = value$5 !== undefined ? value$5 : null;
  var value$6 = value$4.startCursor;
  var startCursor = value$6 !== undefined ? value$6 : null;
  var value$7 = value$4.__typename;
  var pageInfo = {
    __typename: value$7,
    startCursor: startCursor,
    endCursor: endCursor
  };
  var value$8 = value$1.__typename;
  var stockActivities = {
    __typename: value$8,
    pageInfo: pageInfo,
    edges: edges,
    totalCount: value$2
  };
  return {
          stockActivities: stockActivities
        };
}

function serializeInputObjectDateFilter(inp) {
  var a = inp._after;
  var a$1 = inp._before;
  var a$2 = inp._between;
  return {
          _after: a !== undefined ? Caml_option.valFromOption(a) : undefined,
          _before: a$1 !== undefined ? Caml_option.valFromOption(a$1) : undefined,
          _between: a$2 !== undefined ? Js_array.map((function (b) {
                    return b;
                  }), a$2) : undefined
        };
}

function serializeInputObjectInFilter(inp) {
  var a = inp._in;
  return {
          _in: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function serializeInputObjectInputStockActivitiesQueryFilter(inp) {
  var a = inp.kind;
  var a$1 = inp.reason;
  var a$2 = inp.date;
  var a$3 = inp.shopIds;
  return {
          kind: a !== undefined ? serializeInputObjectInFilter(a) : undefined,
          reason: a$1 !== undefined ? serializeInputObjectInFilter(a$1) : undefined,
          date: a$2 !== undefined ? serializeInputObjectDateFilter(a$2) : undefined,
          shopIds: a$3 !== undefined ? serializeInputObjectInFilter(a$3) : undefined
        };
}

function serializeVariables(inp) {
  var a = inp.search;
  var a$1 = inp.before;
  var a$2 = inp.after;
  var a$3 = inp.first;
  var a$4 = inp.last;
  var a$5 = inp.filterBy;
  return {
          search: a !== undefined ? a : undefined,
          before: a$1 !== undefined ? a$1 : undefined,
          after: a$2 !== undefined ? a$2 : undefined,
          first: a$3 !== undefined ? a$3 : undefined,
          last: a$4 !== undefined ? a$4 : undefined,
          filterBy: a$5 !== undefined ? serializeInputObjectInputStockActivitiesQueryFilter(a$5) : undefined
        };
}

function makeVariables(search, before, after, first, last, filterBy, param) {
  return {
          search: search,
          before: before,
          after: after,
          first: first,
          last: last,
          filterBy: filterBy
        };
}

function makeInputObjectInputStockActivitiesQueryFilter(kind, reason, date, shopIds, param) {
  return {
          kind: kind,
          reason: reason,
          date: date,
          shopIds: shopIds
        };
}

function makeInputObjectInFilter(_in, param) {
  return {
          _in: _in
        };
}

function makeInputObjectDateFilter(_after, _before, _between, param) {
  return {
          _after: _after,
          _before: _before,
          _between: _between
        };
}

function makeDefaultVariables(param) {
  return {
          search: undefined,
          before: undefined,
          after: undefined,
          first: undefined,
          last: undefined,
          filterBy: undefined
        };
}

var Query_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputStockActivitiesQueryFilter: serializeInputObjectInputStockActivitiesQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  serializeInputObjectDateFilter: serializeInputObjectDateFilter,
  makeVariables: makeVariables,
  makeInputObjectInputStockActivitiesQueryFilter: makeInputObjectInputStockActivitiesQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeInputObjectDateFilter: makeInputObjectDateFilter,
  makeDefaultVariables: makeDefaultVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var Query_refetchQueryDescription = include.refetchQueryDescription;

var Query_useLazy = include.useLazy;

var Query_useLazyWithVariables = include.useLazyWithVariables;

var Query = {
  Query_inner: Query_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputStockActivitiesQueryFilter: serializeInputObjectInputStockActivitiesQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  serializeInputObjectDateFilter: serializeInputObjectDateFilter,
  makeVariables: makeVariables,
  makeInputObjectInputStockActivitiesQueryFilter: makeInputObjectInputStockActivitiesQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeInputObjectDateFilter: makeInputObjectDateFilter,
  makeDefaultVariables: makeDefaultVariables,
  refetchQueryDescription: Query_refetchQueryDescription,
  use: use,
  useLazy: Query_useLazy,
  useLazyWithVariables: Query_useLazyWithVariables
};

var make = React.memo(function (Props) {
      var notification = Props.notification;
      var onRequestClose = Props.onRequestClose;
      if (notification === undefined) {
        return null;
      }
      var tmp;
      tmp = notification.TAG === /* Ok */0 ? React.createElement(Banner$Pos.make, {
              textStatus: {
                TAG: /* Success */0,
                _0: notification._0
              },
              onRequestClose: onRequestClose
            }) : React.createElement(Banner$Pos.make, {
              textStatus: {
                TAG: /* Danger */1,
                _0: notification._0
              },
              onRequestClose: onRequestClose
            });
      return React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(tmp), undefined, undefined, "medium", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
    });

var NotificationBanner = {
  make: make
};

var endpoint = Env$Pos.sheetUrl(undefined) + "/stock-movements-history";

var Raw$1 = {};

var query$1 = Client.gql(["query StockActivityExportQuery($filterBy: InputStockActivitiesQueryFilter)  {\nstockActivities(filterBy: $filterBy)  {\n__typename  \ntotalCount  \n}\n\n}\n"]);

function parse$1(value) {
  var value$1 = value.stockActivities;
  return {
          stockActivities: {
            __typename: value$1.__typename,
            totalCount: value$1.totalCount
          }
        };
}

function serialize$1(value) {
  var value$1 = value.stockActivities;
  var value$2 = value$1.totalCount;
  var value$3 = value$1.__typename;
  var stockActivities = {
    __typename: value$3,
    totalCount: value$2
  };
  return {
          stockActivities: stockActivities
        };
}

function serializeInputObjectDateFilter$1(inp) {
  var a = inp._after;
  var a$1 = inp._before;
  var a$2 = inp._between;
  return {
          _after: a !== undefined ? Caml_option.valFromOption(a) : undefined,
          _before: a$1 !== undefined ? Caml_option.valFromOption(a$1) : undefined,
          _between: a$2 !== undefined ? Js_array.map((function (b) {
                    return b;
                  }), a$2) : undefined
        };
}

function serializeInputObjectInFilter$1(inp) {
  var a = inp._in;
  return {
          _in: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function serializeInputObjectInputStockActivitiesQueryFilter$1(inp) {
  var a = inp.kind;
  var a$1 = inp.reason;
  var a$2 = inp.date;
  var a$3 = inp.shopIds;
  return {
          kind: a !== undefined ? serializeInputObjectInFilter$1(a) : undefined,
          reason: a$1 !== undefined ? serializeInputObjectInFilter$1(a$1) : undefined,
          date: a$2 !== undefined ? serializeInputObjectDateFilter$1(a$2) : undefined,
          shopIds: a$3 !== undefined ? serializeInputObjectInFilter$1(a$3) : undefined
        };
}

function serializeVariables$1(inp) {
  var a = inp.filterBy;
  return {
          filterBy: a !== undefined ? serializeInputObjectInputStockActivitiesQueryFilter$1(a) : undefined
        };
}

function makeVariables$1(filterBy, param) {
  return {
          filterBy: filterBy
        };
}

function makeInputObjectInputStockActivitiesQueryFilter$1(kind, reason, date, shopIds, param) {
  return {
          kind: kind,
          reason: reason,
          date: date,
          shopIds: shopIds
        };
}

function makeInputObjectInFilter$1(_in, param) {
  return {
          _in: _in
        };
}

function makeInputObjectDateFilter$1(_after, _before, _between, param) {
  return {
          _after: _after,
          _before: _before,
          _between: _between
        };
}

function makeDefaultVariables$1(param) {
  return {
          filterBy: undefined
        };
}

var Query_inner$1 = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  serializeInputObjectInputStockActivitiesQueryFilter: serializeInputObjectInputStockActivitiesQueryFilter$1,
  serializeInputObjectInFilter: serializeInputObjectInFilter$1,
  serializeInputObjectDateFilter: serializeInputObjectDateFilter$1,
  makeVariables: makeVariables$1,
  makeInputObjectInputStockActivitiesQueryFilter: makeInputObjectInputStockActivitiesQueryFilter$1,
  makeInputObjectInFilter: makeInputObjectInFilter$1,
  makeInputObjectDateFilter: makeInputObjectDateFilter$1,
  makeDefaultVariables: makeDefaultVariables$1
};

var include$1 = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$1,
      Raw: Raw$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1
    });

var use$1 = include$1.use;

var Query_refetchQueryDescription$1 = include$1.refetchQueryDescription;

var Query_useLazy$1 = include$1.useLazy;

var Query_useLazyWithVariables$1 = include$1.useLazyWithVariables;

var Query$1 = {
  Query_inner: Query_inner$1,
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  serializeInputObjectInputStockActivitiesQueryFilter: serializeInputObjectInputStockActivitiesQueryFilter$1,
  serializeInputObjectInFilter: serializeInputObjectInFilter$1,
  serializeInputObjectDateFilter: serializeInputObjectDateFilter$1,
  makeVariables: makeVariables$1,
  makeInputObjectInputStockActivitiesQueryFilter: makeInputObjectInputStockActivitiesQueryFilter$1,
  makeInputObjectInFilter: makeInputObjectInFilter$1,
  makeInputObjectDateFilter: makeInputObjectDateFilter$1,
  makeDefaultVariables: makeDefaultVariables$1,
  refetchQueryDescription: Query_refetchQueryDescription$1,
  use: use$1,
  useLazy: Query_useLazy$1,
  useLazyWithVariables: Query_useLazyWithVariables$1
};

function encodeRequestBodyJson(shopIds, kind, reason, dateRange, param) {
  var body = {};
  var match = Intl$Pos.resolvedOptions(Intl$Pos.dateTimeFormatConstructor(undefined, undefined, undefined));
  body["shopIds"] = Json$Pos.encodeArray(Belt_Array.map(shopIds, Json$Pos.encodeString));
  if (kind !== undefined) {
    body["stockActivityKind"] = Json$Pos.encodeString(StockActivityKind$Pos.toString(kind));
  }
  if (reason !== undefined) {
    body["stockActivityLossReason"] = Json$Pos.encodeString(StockActivityReason$Pos.toString(reason));
  }
  if (dateRange !== undefined) {
    body["startDate"] = Json$Pos.encodeString(dateRange[0].toISOString());
    body["endDate"] = Json$Pos.encodeString(dateRange[1].toISOString());
  }
  body["timeZone"] = Json$Pos.encodeString(match.timeZone);
  return Json$Pos.encodeDict(body);
}

function makeVariablesFromFilters(shopIds, kind, reason, dateRange, param) {
  return {
          filterBy: makeInputObjectInputStockActivitiesQueryFilter$1(kind !== undefined ? ({
                    _in: [StockActivityKind$Pos.toString(kind)]
                  }) : undefined, reason !== undefined ? ({
                    _in: [StockActivityReason$Pos.toString(reason)]
                  }) : undefined, dateRange !== undefined ? ({
                    _after: undefined,
                    _before: undefined,
                    _between: [
                      Scalar$Pos.Datetime.serialize(dateRange[0]),
                      Scalar$Pos.Datetime.serialize(dateRange[1])
                    ]
                  }) : undefined, {
                _in: shopIds
              }, undefined)
        };
}

function StockActivityPage$StockActivityExportMenuItem(Props) {
  var text = Props.text;
  var shopIds = Props.shopIds;
  var kind = Props.kind;
  var reason = Props.reason;
  var dateRange = Props.dateRange;
  var onRequestErrorNotification = Props.onRequestErrorNotification;
  var match = Popover$Pos.useState(undefined);
  var onRequestClose = match.close;
  var queryResults = Curry.app(use$1, [
        undefined,
        undefined,
        undefined,
        undefined,
        /* CacheAndNetwork */0,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        makeVariablesFromFilters(shopIds, kind, reason, dateRange, undefined)
      ]);
  var request = function (param) {
    return Request$Pos.makeAndDecode("POST", Caml_option.some(encodeRequestBodyJson(shopIds, kind, reason, dateRange, undefined)), undefined, undefined, endpoint);
  };
  var onSuccess = function (param) {
    Curry._1(onRequestClose, undefined);
  };
  var onFailure = function (error) {
    var errorMessage = RequestOpenStorageUrlMenuItem$Pos.failureErrorToString(error, "stock activities");
    Curry._1(onRequestErrorNotification, errorMessage);
    Curry._1(onRequestClose, undefined);
  };
  var match$1 = queryResults.data;
  var totalCount = match$1 !== undefined ? match$1.stockActivities.totalCount : undefined;
  var match$2 = shopIds.length === 1;
  var operableRequest = match$2 ? (
      totalCount !== undefined ? (
          totalCount !== 0 ? (
              totalCount > 10000 ? ({
                    TAG: /* Error */1,
                    _0: Intl$Pos.template(Intl$Pos.t("{{transactionNumber}} transactions are to be exported,\nthe exported file may be too large.\nPlease modify your filters to launch the export\n(maximum {{maxTransactionNumber}} transactions)."), {
                          transactionNumber: Intl$Pos.decimalFormat(undefined, undefined, totalCount),
                          maxTransactionNumber: Intl$Pos.decimalFormat(undefined, undefined, 10000)
                        }, undefined)
                  }) : ({
                    TAG: /* Ok */0,
                    _0: request
                  })
            ) : ({
                TAG: /* Error */1,
                _0: Intl$Pos.t("No stock activity to export with these filters.")
              })
        ) : ({
            TAG: /* Error */1,
            _0: Intl$Pos.t("Please wait.")
          })
    ) : ({
        TAG: /* Error */1,
        _0: Intl$Pos.t("Please select a shop beforehand with the filter.")
      });
  return React.createElement(RequestOpenStorageUrlMenuItem$Pos.make, {
              text: text,
              operableRequest: operableRequest,
              onSuccess: onSuccess,
              onFailure: onFailure
            });
}

var StockActivityExportMenuItem = {
  endpoint: endpoint,
  Query: Query$1,
  encodeRequestBodyJson: encodeRequestBodyJson,
  makeVariablesFromFilters: makeVariablesFromFilters,
  totalCountLimit: 10000,
  make: StockActivityPage$StockActivityExportMenuItem
};

function encoder(param) {
  return [
          Belt_Option.map(param.shop, (function (shop) {
                  return shop.id;
                })),
          Belt_Option.map(param.dateRange, (function (param) {
                  return [
                          param[0].valueOf(),
                          param[1].valueOf()
                        ];
                })),
          Belt_Option.map(param.kind, StockActivityKind$Pos.toString),
          Belt_Option.map(param.reason, StockActivityReason$Pos.toString)
        ];
}

function decoder(shops, param) {
  var shopId = param[0];
  return {
          TAG: /* Ok */0,
          _0: {
            shop: Belt_Array.getBy(shops, (function (shop) {
                    return shop.id === shopId;
                  })),
            dateRange: Belt_Option.flatMap(param[1], (function (range) {
                    if (range.length !== 2) {
                      return ;
                    }
                    var start = range[0];
                    var end = range[1];
                    return [
                            new Date(start),
                            new Date(end)
                          ];
                  })),
            kind: Belt_Option.map(param[2], StockActivityKind$Pos.fromStringExn),
            reason: Belt_Option.map(param[3], StockActivityReason$Pos.fromStringExn)
          }
        };
}

function useJsonCodec(param) {
  var shops = Auth$Pos.useShops(undefined);
  return JsonCodec$Pos.object4(encoder, (function (param) {
                return decoder(shops, param);
              }), JsonCodec$Pos.optional(JsonCodec$Pos.field("shopId", JsonCodec$Pos.string)), JsonCodec$Pos.optional(JsonCodec$Pos.field("dateRange", JsonCodec$Pos.array(JsonCodec$Pos.$$float))), JsonCodec$Pos.optional(JsonCodec$Pos.field("kind", JsonCodec$Pos.string)), JsonCodec$Pos.optional(JsonCodec$Pos.field("reason", JsonCodec$Pos.string)));
}

var Filters = {
  encoder: encoder,
  decoder: decoder,
  useJsonCodec: useJsonCodec
};

var Row = {};

function makeQueryVariables(_defaultQueryVariables, connectionArguments, search, filterBy, param) {
  return {
          search: search,
          before: connectionArguments.before,
          after: connectionArguments.after,
          first: connectionArguments.first,
          last: connectionArguments.last,
          filterBy: filterBy
        };
}

function makeQueryVariablesFilterBy(param) {
  var reason = param.reason;
  var kind = param.kind;
  var dateRange = param.dateRange;
  var shop = param.shop;
  return makeInputObjectInputStockActivitiesQueryFilter(kind !== undefined ? ({
                  _in: [StockActivityKind$Pos.toString(kind)]
                }) : undefined, reason !== undefined ? ({
                  _in: [StockActivityReason$Pos.toString(reason)]
                }) : undefined, dateRange !== undefined ? ({
                  _after: undefined,
                  _before: undefined,
                  _between: [
                    Scalar$Pos.Datetime.serialize(dateRange[0]),
                    Scalar$Pos.Datetime.serialize(dateRange[1])
                  ]
                }) : undefined, shop !== undefined ? ({
                  _in: [shop.id]
                }) : undefined, undefined);
}

function totalCountFromQueryData(param) {
  return param.stockActivities.totalCount;
}

function cursorsFromQueryData(param) {
  var stockActivities = param.stockActivities;
  return [
          stockActivities.pageInfo.startCursor,
          stockActivities.pageInfo.endCursor
        ];
}

function rowsFromQueryDataAndState(param, param$1) {
  return Belt_Array.keepMap(param.stockActivities.edges, (function (param) {
                var node = param.node;
                var variant = node.variant;
                if (variant === undefined) {
                  return ;
                }
                var match = variant.bulk;
                var match$1 = variant.capacityPrecision;
                var match$2 = variant.bulk;
                var match$3 = variant.capacityUnit;
                return {
                        id: node.id,
                        variantId: variant.id,
                        variantCku: variant.cku,
                        variantName: variant.formattedName,
                        variantDescription: variant.formattedDescription,
                        variantStockKeepingUnit: variant.stockKeepingUnit,
                        variantCapacityPrecision: match !== undefined && match && match$1 !== undefined ? match$1 : undefined,
                        variantCapacityUnit: match$2 !== undefined && match$2 && match$3 !== undefined ? match$3 : undefined,
                        color: Belt_Option.flatMap(Belt_Option.map(node.variant, (function (variant) {
                                    return variant.product;
                                  })), (function (product) {
                                return product.color;
                              })),
                        formattedKind: StockActivityKind$Pos.toLabel(node.kind),
                        kind: node.kind,
                        reason: node.reason,
                        quantity: node.quantity,
                        description: node.comment,
                        date: node.createdAt,
                        shopName: node.shop.name,
                        deviceName: Device$Pos.formatDeviceName(node.device.name, node.device.slug)
                      };
              }));
}

function keyExtractor(param) {
  return param.id;
}

var Scaffolded = Scaffold$Pos.Make({
      useFiltersJsonCodec: useJsonCodec,
      QueryInner: Query_inner,
      useQuery: use,
      makeQueryVariables: makeQueryVariables,
      makeQueryVariablesFilterBy: makeQueryVariablesFilterBy,
      cursorsFromQueryData: cursorsFromQueryData,
      totalCountFromQueryData: totalCountFromQueryData,
      rowsFromQueryDataAndState: rowsFromQueryDataAndState,
      keyExtractor: keyExtractor
    });

function makeFromStateExn(param) {
  var filters = param.filters;
  var shopId = Belt_Option.map(filters.shop, (function (param) {
          return param.id;
        }));
  return {
          shopId: shopId,
          kind: filters.kind,
          reason: filters.reason,
          dateRange: filters.dateRange
        };
}

var SheetExportInput = {
  makeFromStateExn: makeFromStateExn
};

function use$2(initialState) {
  var match = Curry._1(Scaffolded.use, (function (param) {
          return initialState;
        }));
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          var match = state.filters;
          var match$1 = match.kind;
          var exit = 0;
          if (match$1 !== "LOSS") {
            exit = 1;
          }
          if (exit === 1) {
            if (match.reason !== undefined) {
              Curry._1(dispatch, {
                    TAG: /* FiltersUpdated */2,
                    _0: (function (prev) {
                        return {
                                shop: prev.shop,
                                dateRange: prev.dateRange,
                                kind: prev.kind,
                                reason: undefined
                              };
                      })
                  });
            }
            
          }
          
        }), [state.filters]);
  return [
          state,
          dispatch
        ];
}

function StockActivityPage(Props) {
  var scope = Auth$Pos.useScope(undefined);
  var organisationAccount;
  organisationAccount = scope.TAG === /* Organisation */0 ? true : false;
  var tmp;
  tmp = scope.TAG === /* Organisation */0 ? scope.activeShop : scope._0;
  var initialState = Curry._1(Scaffolded.makeInitialState, {
        shop: tmp,
        dateRange: undefined,
        kind: undefined,
        reason: undefined
      });
  var match = use$2(initialState);
  var dispatch = match[1];
  var state = match[0];
  var defaultQueryVariables = {
    search: undefined,
    before: undefined,
    after: undefined,
    first: undefined,
    last: undefined,
    filterBy: undefined
  };
  var match$1 = React.useState(function () {
        
      });
  var setNotification = match$1[1];
  var columns = [
    {
      name: Intl$Pos.t("Product name and description"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 250
        },
        width: {
          NAME: "pct",
          VAL: 30
        },
        margin: "xxlarge",
        sticky: true
      },
      render: (function (activity) {
          var pastilleColor = Belt_Option.map(activity.color, (function (color) {
                  return CatalogProduct$Pos.Color.toColorSet(color, "pastille").foregroundColor;
                }));
          var tmp = {
            cku: activity.variantCku,
            name: activity.variantName,
            description: activity.variantDescription
          };
          if (pastilleColor !== undefined) {
            tmp.pastilleColor = Caml_option.valFromOption(pastilleColor);
          }
          if (activity.variantStockKeepingUnit !== undefined) {
            tmp.stockKeepingUnit = activity.variantStockKeepingUnit;
          }
          return React.createElement(ProductReferenceTableCell$Pos.make, tmp);
        })
    },
    {
      name: Intl$Pos.t("Type"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 120
        },
        width: {
          NAME: "fr",
          VAL: 0.5
        }
      },
      render: (function (param) {
          return React.createElement(StockActivityTypeTableCell$Pos.make, {
                      value: param.formattedKind,
                      reason: param.reason
                    });
        })
    },
    {
      name: Intl$Pos.t("Quantity"),
      layout: {
        width: {
          NAME: "fr",
          VAL: 0.5
        },
        alignX: "center",
        sticky: true
      },
      render: (function (param) {
          var tmp = {
            value: param.quantity,
            kind: param.kind
          };
          if (param.variantCapacityPrecision !== undefined) {
            tmp.capacityPrecision = Caml_option.valFromOption(param.variantCapacityPrecision);
          }
          if (param.variantCapacityUnit !== undefined) {
            tmp.capacityUnit = Caml_option.valFromOption(param.variantCapacityUnit);
          }
          return React.createElement(StockActivityQuantityTableCell$Pos.make, tmp);
        })
    },
    {
      name: Intl$Pos.t("Comment"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 100
        },
        margin: "normal"
      },
      render: (function (param) {
          return React.createElement(TextStyle$Pos.make, {
                      children: Belt_Option.getWithDefault(param.description, "-")
                    });
        })
    },
    {
      name: Intl$Pos.t("Datetime"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 110
        }
      },
      render: (function (param) {
          return React.createElement(TableDatetimeCell$Pos.make, {
                      value: param.date
                    });
        })
    },
    {
      name: Intl$Pos.t("Source"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 120
        }
      },
      render: (function (param) {
          var shopName;
          shopName = scope.TAG === /* Organisation */0 ? param.shopName : undefined;
          var tmp = {
            deviceName: param.deviceName
          };
          if (shopName !== undefined) {
            tmp.shopName = Caml_option.valFromOption(shopName);
          }
          return React.createElement(StockActivitySourceTableCell$Pos.make, tmp);
        })
    }
  ];
  var tmp$1;
  if (organisationAccount) {
    var tmp$2 = {
      onChange: (function (shop) {
          Curry._1(dispatch, {
                TAG: /* FiltersUpdated */2,
                _0: (function (prev) {
                    return {
                            shop: shop,
                            dateRange: prev.dateRange,
                            kind: prev.kind,
                            reason: prev.reason
                          };
                  })
              });
        })
    };
    if (state.filters.shop !== undefined) {
      tmp$2.value = Caml_option.valFromOption(state.filters.shop);
    }
    tmp$1 = React.createElement(Auth$Pos.SelectShop.make, tmp$2);
  } else {
    tmp$1 = null;
  }
  var defaultItem_label = Intl$Pos.t("All");
  var defaultItem_sticky = true;
  var defaultItem = {
    key: "default",
    label: defaultItem_label,
    sticky: defaultItem_sticky
  };
  var items = Belt_Array.map(StockActivityKind$Pos.values, (function (value) {
          return {
                  key: StockActivityKind$Pos.toString(value),
                  value: value,
                  label: StockActivityKind$Pos.toLabel(value)
                };
        }));
  var match$2 = state.filters.kind;
  var tmp$3;
  if (match$2 === "LOSS") {
    var defaultItem_label$1 = Intl$Pos.template(Intl$Pos.t("All{{feminine}}"), undefined, undefined);
    var defaultItem_sticky$1 = true;
    var defaultItem$1 = {
      key: "default",
      label: defaultItem_label$1,
      sticky: defaultItem_sticky$1
    };
    var items$1 = Belt_Array.map(StockActivityReason$Pos.values, (function (value) {
            return {
                    key: StockActivityReason$Pos.toString(value),
                    value: value,
                    label: StockActivityReason$Pos.toLabel(value)
                  };
          }));
    tmp$3 = React.createElement(Select$Pos.make, {
          label: Intl$Pos.t("Reason"),
          sections: [
            {
              items: [defaultItem$1]
            },
            {
              items: items$1,
              title: Intl$Pos.t("Reasons")
            }
          ],
          value: state.filters.reason,
          onChange: (function (reason) {
              Curry._1(dispatch, {
                    TAG: /* FiltersUpdated */2,
                    _0: (function (prev) {
                        return {
                                shop: prev.shop,
                                dateRange: prev.dateRange,
                                kind: prev.kind,
                                reason: reason
                              };
                      })
                  });
            })
        });
  } else {
    tmp$3 = null;
  }
  var tmp$4 = {
    placeholder: Intl$Pos.t("Since the beginning"),
    onChange: (function (dateRange) {
        Curry._1(dispatch, {
              TAG: /* FiltersUpdated */2,
              _0: (function (prev) {
                  return {
                          shop: prev.shop,
                          dateRange: dateRange,
                          kind: prev.kind,
                          reason: prev.reason
                        };
                })
            });
      })
  };
  if (state.filters.dateRange !== undefined) {
    tmp$4.value = Caml_option.valFromOption(state.filters.dateRange);
  }
  var match$3 = state.filters;
  var tmp$5;
  var exit = 0;
  if (match$3.dateRange !== undefined || match$3.kind !== undefined) {
    exit = 1;
  } else {
    tmp$5 = null;
  }
  if (exit === 1) {
    tmp$5 = React.createElement(Scaffold$Pos.ResetFiltersButton.make, {
          onPress: (function (param) {
              Curry._1(dispatch, {
                    TAG: /* FiltersUpdated */2,
                    _0: (function (param) {
                        return initialState.filters;
                      })
                  });
            })
        });
  }
  var filters = React.createElement(Inline$Pos.make, {
        children: null,
        space: "small"
      }, tmp$1, organisationAccount ? React.createElement(Separator$Pos.make, {}) : null, React.createElement(Select$Pos.make, {
            label: Intl$Pos.t("Kind"),
            sections: [
              {
                items: [defaultItem]
              },
              {
                items: items,
                title: Intl$Pos.t("Kinds")
              }
            ],
            value: state.filters.kind,
            onChange: (function (kind) {
                Curry._1(dispatch, {
                      TAG: /* FiltersUpdated */2,
                      _0: (function (prev) {
                          return {
                                  shop: prev.shop,
                                  dateRange: prev.dateRange,
                                  kind: kind,
                                  reason: prev.reason
                                };
                        })
                    });
              })
          }), tmp$3, React.createElement(SelectDateRange$Pos.make, tmp$4), tmp$5);
  var shops = Auth$Pos.useShops(undefined);
  var match$4 = makeFromStateExn(state);
  var shopId = match$4.shopId;
  var shopIds = shopId !== undefined ? [shopId] : Belt_Array.map(shops, (function (shop) {
            return shop.id;
          }));
  var onRequestErrorNotification = function (error) {
    Curry._1(setNotification, (function (param) {
            return {
                    TAG: /* Error */1,
                    _0: error
                  };
          }));
  };
  var tmp$6 = {
    text: Intl$Pos.t("Export"),
    shopIds: shopIds,
    onRequestErrorNotification: onRequestErrorNotification
  };
  if (match$4.kind !== undefined) {
    tmp$6.kind = Caml_option.valFromOption(match$4.kind);
  }
  if (match$4.reason !== undefined) {
    tmp$6.reason = Caml_option.valFromOption(match$4.reason);
  }
  if (match$4.dateRange !== undefined) {
    tmp$6.dateRange = Caml_option.valFromOption(match$4.dateRange);
  }
  var actions = React.createElement(Menu$Pos.make, {
        children: React.createElement(StockActivityPage$StockActivityExportMenuItem, tmp$6),
        overlayPriority: false
      });
  var banner = React.createElement(make, {
        notification: match$1[0],
        onRequestClose: (function (param) {
            Curry._1(setNotification, (function (param) {
                    
                  }));
          })
      });
  var activeShop;
  activeShop = scope.TAG === /* Organisation */0 ? scope.activeShop : scope._0;
  var tmp$7 = {
    placeholder: Intl$Pos.t("Search a stock movement"),
    onChange: (function (searchQuery) {
        Curry._1(dispatch, {
              TAG: /* Searched */1,
              _0: searchQuery
            });
      })
  };
  if (state.searchQuery !== undefined) {
    tmp$7.value = state.searchQuery;
  }
  var searchBar = React.createElement(SearchBar$Pos.make, tmp$7);
  var emptyState;
  var exit$1 = 0;
  if (state.currentPage !== 1 || state.searchQuery !== undefined) {
    exit$1 = 1;
  } else {
    var match$5 = state.filters;
    if (match$5.dateRange !== undefined || match$5.kind !== undefined || match$5.reason !== undefined || match$5.shop !== activeShop) {
      exit$1 = 1;
    } else {
      emptyState = React.createElement(EmptyState$Pos.make, {
            illustration: Illustration$Pos.create,
            title: Intl$Pos.t("Welcome to the stock movements space.")
          });
    }
  }
  if (exit$1 === 1) {
    emptyState = React.createElement(EmptyState$Pos.make, {
          illustration: Illustration$Pos.notFound,
          title: Intl$Pos.t("No result were found."),
          text: Intl$Pos.t("Try again with another keyword/filter or:"),
          children: React.createElement(Button$Pos.make, Button$Pos.makeProps(Intl$Pos.t("Clear search query and filters"), undefined, "neutral", undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                      Curry._1(dispatch, {
                            TAG: /* Reset */3,
                            _0: initialState
                          });
                    }), undefined, undefined, undefined))
        });
  }
  return React.createElement(Scaffolded.make, {
              title: Intl$Pos.t("Stock activities"),
              state: state,
              dispatch: dispatch,
              columns: columns,
              filters: filters,
              actions: actions,
              banner: banner,
              searchBar: searchBar,
              emptyState: emptyState,
              defaultQueryVariables: defaultQueryVariables
            });
}

var make$1 = React.memo(StockActivityPage);

export {
  Query ,
  NotificationBanner ,
  StockActivityExportMenuItem ,
  Filters ,
  Row ,
  Scaffolded ,
  SheetExportInput ,
  use$2 as use,
  make$1 as make,
}
/* query Not a pure module */
