// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Env$Pos from "../../core/Env.bs.js";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Json$Pos from "../../primitives/Json.bs.js";
import * as Badge$Pos from "../../resources/feedback-indicators/Badge.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Button$Pos from "../../resources/actions/Button.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Request$Pos from "../../core/Request.bs.js";
import * as TextLink$Pos from "../../resources/navigation/TextLink.bs.js";
import * as SearchBar$Pos from "../../resources/selection-and-input/SearchBar.bs.js";
import * as TableView$Pos from "../../resources/tables/TableView.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as EmptyState$Pos from "../../resources/layout-and-structure/EmptyState.bs.js";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as Pagination$Pos from "../../resources/navigation/Pagination.bs.js";
import * as AsyncResult$Pos from "../../primitives/AsyncResult.bs.js";
import * as RoundButton$Pos from "../../resources/actions/RoundButton.bs.js";
import * as Illustration$Pos from "../../resources/images-and-icons/Illustration.bs.js";
import * as SettingsRoutes$Pos from "../../app/Settings/SettingsRoutes.bs.js";
import * as ShowAllDataLink$Pos from "../../resources/navigation/ShowAllDataLink.bs.js";

function fromString(status) {
  switch (status) {
    case "open" :
        return /* Open */0;
    case "paid" :
        return /* Paid */1;
    case "uncollectible" :
        return /* Uncollectible */2;
    case "void" :
        return /* Void */3;
    default:
      return ;
  }
}

function toString(status) {
  switch (status) {
    case /* Open */0 :
        return "Amount due";
    case /* Paid */1 :
        return "Paid";
    case /* Uncollectible */2 :
        return "Uncollectible";
    case /* Void */3 :
        return "Credited balance";
    
  }
}

var BillingAccountInvoiceStatus = {
  fromString: fromString,
  toString: toString
};

function decodeInvoice(json) {
  var dict = Json$Pos.decodeDict(json);
  var match = Json$Pos.flatDecodeDictFieldString(dict, "id");
  var match$1 = Json$Pos.flatDecodeDictFieldString(dict, "number");
  var match$2 = Json$Pos.flatDecodeDictFieldFloat(dict, "total");
  var match$3 = Json$Pos.flatDecodeDictFieldString(dict, "status");
  var match$4 = Json$Pos.flatDecodeDictFieldString(dict, "pdfLink");
  var match$5 = Json$Pos.flatDecodeDictFieldFloat(dict, "date");
  if (match !== undefined && match$1 !== undefined && match$2 !== undefined && match$5 !== undefined) {
    return {
            id: match,
            number: match$1,
            total: match$2,
            status: match$3 !== undefined ? fromString(match$3) : undefined,
            pdfLink: match$4,
            date: new Date(match$5)
          };
  }
  
}

function decodeResult(json) {
  var resultItems = Json$Pos.flatDecodeDictFieldArray(Json$Pos.decodeDict(json), "data");
  if (resultItems !== undefined) {
    return Belt_Array.keepMap(resultItems, decodeInvoice);
  } else {
    return [];
  }
}

var endpoint = Env$Pos.gatewayUrl(undefined) + "/customer-invoices/";

function make(shopId) {
  return Future.mapOk(Request$Pos.make("GET", undefined, undefined, undefined, endpoint + shopId), undefined, decodeResult);
}

var BillingAccountInvoicesRequest = {
  decodeInvoice: decodeInvoice,
  decodeResult: decodeResult,
  endpoint: endpoint,
  make: make
};

function keyExtractor(row) {
  return row.number;
}

function sanitize(string) {
  return string.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z0-9 ]/g, "");
}

function match(row, query) {
  if (query === "" || sanitize(row.number).includes(sanitize(query)) || sanitize(row.date).includes(sanitize(query))) {
    return true;
  } else {
    return sanitize(String(row.total)).includes(sanitize(query));
  }
}

function fromQueryItem(queryItem) {
  return {
          number: queryItem.number,
          date: Intl$Pos.dateTimeFormat(undefined, "short", queryItem.date),
          total: queryItem.total,
          status: queryItem.status,
          pdfLink: Belt_Option.getWithDefault(queryItem.pdfLink, "")
        };
}

var TableRow = {
  keyExtractor: keyExtractor,
  sanitize: sanitize,
  match: match,
  fromQueryItem: fromQueryItem
};

function totalPages(rows) {
  return Math.ceil(rows.length / 10) | 0;
}

function search(rows, searchQuery) {
  return Belt_Array.keep(rows, (function (row) {
                return match(row, searchQuery);
              }));
}

function paginate(rows, currentPage) {
  return Belt_Array.slice(rows, Math.imul(currentPage - 1 | 0, 10), 10);
}

function fromQueryAllShops(queryAllShops) {
  return Belt_Array.map(queryAllShops, fromQueryItem);
}

var TableRows = {
  rowsPerPage: 10,
  totalPages: totalPages,
  search: search,
  paginate: paginate,
  fromQueryAllShops: fromQueryAllShops
};

function BillingAccountInvoices$BillingAccountInvoicesTable$InvoiceStatusBadge(Props) {
  var value = Props.value;
  var sizeOpt = Props.size;
  var size = sizeOpt !== undefined ? sizeOpt : "normal";
  var variation = value !== undefined ? (
      value !== 1 ? (
          value !== 0 ? "information" : "warning"
        ) : "success"
    ) : "information";
  return React.createElement(Badge$Pos.make, {
              children: Intl$Pos.t(toString(Belt_Option.getWithDefault(value, /* Void */3))),
              size: size,
              variation: variation
            });
}

var InvoiceStatusBadge = {
  make: BillingAccountInvoices$BillingAccountInvoicesTable$InvoiceStatusBadge
};

var tableColumns = [
  {
    key: "number",
    name: Intl$Pos.t("Number"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 260
      },
      width: {
        NAME: "fr",
        VAL: 1.5
      }
    },
    render: (function (param) {
        var match = param.data;
        return React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(TextLink$Pos.make, {
                                text: match.number,
                                to: {
                                  TAG: /* Url */2,
                                  _0: new URL(match.pdfLink)
                                }
                              })), undefined, undefined, "small", "small", undefined, undefined, undefined, undefined, undefined, undefined, undefined));
      })
  },
  {
    key: "date",
    name: Intl$Pos.t("Date"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 160
      }
    },
    render: (function (param) {
        return React.createElement(TextStyle$Pos.make, {
                    children: param.data.date
                  });
      })
  },
  {
    key: "amount",
    name: Intl$Pos.t("Amount"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 160
      }
    },
    render: (function (param) {
        return React.createElement(TextStyle$Pos.make, {
                    children: Intl$Pos.currencyFormat("EUR", 2, 2, param.data.total)
                  });
      })
  },
  {
    key: "status",
    name: Intl$Pos.t("Status"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 160
      }
    },
    render: (function (param) {
        return React.createElement(BillingAccountInvoices$BillingAccountInvoicesTable$InvoiceStatusBadge, {
                    value: param.data.status
                  });
      })
  },
  {
    key: "link",
    name: "",
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 50
      },
      alignX: "flexEnd"
    },
    render: (function (param) {
        return React.createElement(Navigation$Pos.Link.make, {
                    children: React.createElement(RoundButton$Pos.make, RoundButton$Pos.makeProps(undefined, "download", undefined, undefined, undefined, (function (param) {
                                
                              }), undefined, undefined, undefined)),
                    to: {
                      TAG: /* Url */2,
                      _0: new URL(param.data.pdfLink)
                    }
                  });
      })
  }
];

var initialState_asyncResult = AsyncResult$Pos.notAsked(undefined);

var initialState = {
  searchQuery: "",
  currentPage: 1,
  asyncResult: initialState_asyncResult
};

function make$1(prevState, action) {
  switch (action.TAG | 0) {
    case /* SearchQueryChanged */0 :
        return {
                searchQuery: action._0,
                currentPage: 1,
                asyncResult: prevState.asyncResult
              };
    case /* AsyncResultGet */1 :
        return {
                searchQuery: prevState.searchQuery,
                currentPage: 1,
                asyncResult: action._0
              };
    case /* Paginated */2 :
        var totalPages = action._1;
        var match = prevState.currentPage;
        var tmp;
        switch (action._0) {
          case /* First */0 :
              tmp = 1;
              break;
          case /* Prev */1 :
              tmp = match !== 1 ? prevState.currentPage - 1 | 0 : 1;
              break;
          case /* Next */2 :
              tmp = match >= totalPages ? match : prevState.currentPage + 1 | 0;
              break;
          case /* Last */3 :
              tmp = totalPages;
              break;
          
        }
        return {
                searchQuery: prevState.searchQuery,
                currentPage: tmp,
                asyncResult: prevState.asyncResult
              };
    
  }
}

var Reducer = {
  initialState: initialState,
  make: make$1
};

function BillingAccountInvoices$BillingAccountInvoicesTable(Props) {
  var invoicesRequest = Props.invoicesRequest;
  var preview = Props.preview;
  var match = React.useReducer(make$1, initialState);
  var state = match[0];
  var currentPage = state.currentPage;
  var searchQuery = state.searchQuery;
  var dispatch = match[1];
  var scope = Auth$Pos.useScope(undefined);
  var activeShop;
  if (scope.TAG === /* Organisation */0) {
    var shop = scope.activeShop;
    activeShop = shop !== undefined ? shop : scope.shops[0];
  } else {
    activeShop = scope._0;
  }
  React.useEffect((function () {
          var request = Future.mapError(Curry._1(invoicesRequest, activeShop.id), undefined, (function (param) {
                  
                }));
          Curry._1(dispatch, {
                TAG: /* AsyncResultGet */1,
                _0: AsyncResult$Pos.loading(undefined)
              });
          Future.get(request, (function (result) {
                  Curry._1(dispatch, {
                        TAG: /* AsyncResultGet */1,
                        _0: AsyncResult$Pos.done(result)
                      });
                }));
          return (function (param) {
                    Future.cancel(request);
                  });
        }), [activeShop]);
  var tableRows = AsyncResult$Pos.mapOk(state.asyncResult, fromQueryAllShops);
  var searchedAndFilteredTableRows = AsyncResult$Pos.mapOk(tableRows, (function (rows) {
          if (preview) {
            return Belt_Array.slice(rows, 0, 5);
          } else {
            return search(rows, searchQuery);
          }
        }));
  var totalPages$1;
  if (typeof searchedAndFilteredTableRows === "number") {
    totalPages$1 = 1;
  } else if (searchedAndFilteredTableRows.TAG === /* Reloading */0) {
    var rows = searchedAndFilteredTableRows._0;
    totalPages$1 = rows.TAG === /* Ok */0 ? totalPages(rows._0) : 1;
  } else {
    var rows$1 = searchedAndFilteredTableRows._0;
    totalPages$1 = rows$1.TAG === /* Ok */0 ? totalPages(rows$1._0) : 1;
  }
  var searchedAndPaginatedTableRows = AsyncResult$Pos.mapOk(searchedAndFilteredTableRows, (function (rows) {
          return paginate(rows, currentPage);
        }));
  var onRequestSearch = function (queryString) {
    Curry._1(dispatch, {
          TAG: /* SearchQueryChanged */0,
          _0: queryString
        });
  };
  var onRequestPaginate = function (paginateAction) {
    Curry._1(dispatch, {
          TAG: /* Paginated */2,
          _0: paginateAction,
          _1: totalPages$1
        });
  };
  var match$1 = state.asyncResult;
  var placeholderNoRows;
  var exit = 0;
  if (typeof searchedAndPaginatedTableRows === "number" || searchedAndPaginatedTableRows.TAG !== /* Done */1) {
    exit = 1;
  } else {
    var match$2 = searchedAndPaginatedTableRows._0;
    if (match$2.TAG === /* Ok */0 && match$2._0.length === 0) {
      if (typeof match$1 === "number" || match$1.TAG !== /* Done */1) {
        placeholderNoRows = EmptyState$Pos.error;
      } else {
        var rows$2 = match$1._0;
        if (rows$2.TAG === /* Ok */0) {
          if (rows$2._0.length !== 0 && preview === false) {
            placeholderNoRows = React.createElement(EmptyState$Pos.make, {
                  illustration: Illustration$Pos.notFound,
                  title: Intl$Pos.t("No result were found."),
                  text: Intl$Pos.t("Try again with another keyword or:"),
                  children: React.createElement(Button$Pos.make, Button$Pos.makeProps(Intl$Pos.t("Clear search query"), undefined, "neutral", undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                              Curry._1(dispatch, {
                                    TAG: /* SearchQueryChanged */0,
                                    _0: ""
                                  });
                            }), undefined, undefined, undefined))
                });
          } else {
            exit = 1;
          }
        } else {
          placeholderNoRows = EmptyState$Pos.error;
        }
      }
    } else {
      exit = 1;
    }
  }
  if (exit === 1) {
    if (typeof match$1 === "number" || match$1.TAG !== /* Done */1) {
      placeholderNoRows = EmptyState$Pos.error;
    } else {
      var match$3 = match$1._0;
      placeholderNoRows = match$3.TAG === /* Ok */0 && match$3._0.length === 0 ? React.createElement(EmptyState$Pos.make, {
              illustration: Illustration$Pos.notFound,
              title: Intl$Pos.t("Sorry, no invoices were found.")
            }) : EmptyState$Pos.error;
    }
  }
  var searchBar = preview ? null : React.createElement(SearchBar$Pos.make, {
          placeholder: Intl$Pos.t("Search an invoice"),
          value: searchQuery,
          onChange: onRequestSearch
        });
  var tmp;
  if (typeof tableRows === "number" || tableRows.TAG !== /* Done */1) {
    tmp = null;
  } else {
    var rows$3 = tableRows._0;
    tmp = preview ? (
        rows$3.TAG === /* Ok */0 && preview && rows$3._0.length > 5 ? React.createElement(ShowAllDataLink$Pos.make, {
                to: {
                  TAG: /* Route */0,
                  _0: SettingsRoutes$Pos.invoicesRoute
                },
                text: Intl$Pos.t("Show all invoices")
              }) : null
      ) : React.createElement(Pagination$Pos.make, {
            currentPage: currentPage,
            totalPages: totalPages$1,
            onRequestPaginate: onRequestPaginate
          });
  }
  return React.createElement(React.Fragment, undefined, React.createElement(TableView$Pos.make, {
                  data: searchedAndPaginatedTableRows,
                  columns: tableColumns,
                  keyExtractor: keyExtractor,
                  placeholderEmptyState: placeholderNoRows,
                  searchBar: searchBar
                }), tmp);
}

var BillingAccountInvoicesTable = {
  TableRow: TableRow,
  TableRows: TableRows,
  InvoiceStatusBadge: InvoiceStatusBadge,
  tableColumns: tableColumns,
  Reducer: Reducer,
  make: BillingAccountInvoices$BillingAccountInvoicesTable
};

var invoicesRequest = make;

export {
  BillingAccountInvoiceStatus ,
  BillingAccountInvoicesRequest ,
  invoicesRequest ,
  BillingAccountInvoicesTable ,
}
/* endpoint Not a pure module */
