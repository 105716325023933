// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Scalar$Pos from "../../core/Scalar.bs.js";
import * as FuturePromise from "rescript-future/src/FuturePromise.bs.js";
import * as Client from "@apollo/client";
import * as StockTransferForm$Pos from "./StockTransferForm.bs.js";
import * as ReactUpdateReducer$Pos from "../../primitives/ReactUpdateReducer.bs.js";
import * as ReasonMLCommunity__ApolloClient from "rescript-apollo-client/src/ReasonMLCommunity__ApolloClient.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = Client.gql(["query VariantsByCkuQuery($cku: CKU!, $filterBy: InputVariantsByCkuQueryFilter)  {\nvariantsByCku(cku: $cku, filterBy: $filterBy)  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nid  \nstock  {\n__typename  \nrawQuantity  \nstate  \n}\n\n}\n\n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.variantsByCku;
  var value$2 = value$1.edges;
  return {
          variantsByCku: {
            __typename: value$1.__typename,
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.stock;
                    var value$3 = value$2.rawQuantity;
                    var value$4 = value$2.state;
                    var tmp;
                    if (value$4 == null) {
                      tmp = undefined;
                    } else {
                      var tmp$1;
                      switch (value$4) {
                        case "ALERT" :
                            tmp$1 = "ALERT";
                            break;
                        case "DANGER" :
                            tmp$1 = "DANGER";
                            break;
                        case "OK" :
                            tmp$1 = "OK";
                            break;
                        default:
                          throw {
                                RE_EXN_ID: "Not_found",
                                Error: new Error()
                              };
                      }
                      tmp = tmp$1;
                    }
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              id: value$1.id,
                              stock: {
                                __typename: value$2.__typename,
                                rawQuantity: !(value$3 == null) ? value$3 : undefined,
                                state: tmp
                              }
                            }
                          };
                  }), value$2)
          }
        };
}

function serialize(value) {
  var value$1 = value.variantsByCku;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.stock;
          var value$3 = value$2.state;
          var state = value$3 !== undefined ? (
              value$3 === "OK" ? "OK" : (
                  value$3 === "ALERT" ? "ALERT" : "DANGER"
                )
            ) : null;
          var value$4 = value$2.rawQuantity;
          var rawQuantity = value$4 !== undefined ? value$4 : null;
          var value$5 = value$2.__typename;
          var stock = {
            __typename: value$5,
            rawQuantity: rawQuantity,
            state: state
          };
          var value$6 = value$1.id;
          var value$7 = value$1.__typename;
          var node = {
            __typename: value$7,
            id: value$6,
            stock: stock
          };
          var value$8 = value.__typename;
          return {
                  __typename: value$8,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.__typename;
  var variantsByCku = {
    __typename: value$3,
    edges: edges
  };
  return {
          variantsByCku: variantsByCku
        };
}

function serializeInputObjectInFilter(inp) {
  var a = inp._in;
  return {
          _in: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function serializeInputObjectBooleanEqualsFilter(inp) {
  return {
          _equals: inp._equals
        };
}

function serializeInputObjectInputVariantsByCkuQueryFilter(inp) {
  var a = inp.shopIds;
  var a$1 = inp.active;
  var a$2 = inp.archived;
  return {
          shopIds: a !== undefined ? serializeInputObjectInFilter(a) : undefined,
          active: a$1 !== undefined ? ({
                _equals: a$1._equals
              }) : undefined,
          archived: a$2 !== undefined ? (
              a$2 === "ONLY" ? "ONLY" : (
                  a$2 === "INCLUDED" ? "INCLUDED" : "EXCLUDED"
                )
            ) : undefined
        };
}

function serializeVariables(inp) {
  var a = inp.filterBy;
  return {
          cku: inp.cku,
          filterBy: a !== undefined ? serializeInputObjectInputVariantsByCkuQueryFilter(a) : undefined
        };
}

function makeVariables(cku, filterBy, param) {
  return {
          cku: cku,
          filterBy: filterBy
        };
}

function makeInputObjectInputVariantsByCkuQueryFilter(shopIds, active, archived, param) {
  return {
          shopIds: shopIds,
          active: active,
          archived: archived
        };
}

function makeInputObjectInFilter(_in, param) {
  return {
          _in: _in
        };
}

function makeInputObjectBooleanEqualsFilter(_equals, param) {
  return {
          _equals: _equals
        };
}

var VariantsByCkuQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputVariantsByCkuQueryFilter: serializeInputObjectInputVariantsByCkuQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  serializeInputObjectBooleanEqualsFilter: serializeInputObjectBooleanEqualsFilter,
  makeVariables: makeVariables,
  makeInputObjectInputVariantsByCkuQueryFilter: makeInputObjectInputVariantsByCkuQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeInputObjectBooleanEqualsFilter: makeInputObjectBooleanEqualsFilter
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var VariantsByCkuQuery_refetchQueryDescription = include.refetchQueryDescription;

var VariantsByCkuQuery_use = include.use;

var VariantsByCkuQuery_useLazy = include.useLazy;

var VariantsByCkuQuery_useLazyWithVariables = include.useLazyWithVariables;

var VariantsByCkuQuery = {
  VariantsByCkuQuery_inner: VariantsByCkuQuery_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputVariantsByCkuQueryFilter: serializeInputObjectInputVariantsByCkuQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  serializeInputObjectBooleanEqualsFilter: serializeInputObjectBooleanEqualsFilter,
  makeVariables: makeVariables,
  makeInputObjectInputVariantsByCkuQueryFilter: makeInputObjectInputVariantsByCkuQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeInputObjectBooleanEqualsFilter: makeInputObjectBooleanEqualsFilter,
  refetchQueryDescription: VariantsByCkuQuery_refetchQueryDescription,
  use: VariantsByCkuQuery_use,
  useLazy: VariantsByCkuQuery_useLazy,
  useLazyWithVariables: VariantsByCkuQuery_useLazyWithVariables
};

function getVariantByCkuAndShopId(client, cku, shopId) {
  return Future.map(FuturePromise.fromPromise(Curry._6(client.rescript_query, {
                      query: query,
                      Raw: Raw,
                      parse: parse,
                      serialize: serialize,
                      serializeVariables: serializeVariables
                    }, undefined, undefined, /* NetworkOnly */2, undefined, {
                      cku: Scalar$Pos.CKU.serialize(cku),
                      filterBy: {
                        shopIds: {
                          _in: [shopId]
                        },
                        active: undefined,
                        archived: undefined
                      }
                    })), undefined, (function (results) {
                if (results.TAG !== /* Ok */0) {
                  return {
                          TAG: /* Error */1,
                          _0: undefined
                        };
                }
                var match = results._0;
                if (match.TAG !== /* Ok */0) {
                  return {
                          TAG: /* Error */1,
                          _0: undefined
                        };
                }
                var match$1 = match._0;
                if (match$1.error !== undefined) {
                  return {
                          TAG: /* Error */1,
                          _0: undefined
                        };
                } else {
                  return {
                          TAG: /* Ok */0,
                          _0: match$1.data.variantsByCku.edges
                        };
                }
              }));
}

var initialState_products = [];

var initialState = {
  processing: false,
  products: initialState_products
};

function reducer(client, recipientShopId, state, action) {
  if (typeof action === "number") {
    if (action === /* ResetRequested */0) {
      return {
              TAG: /* Update */0,
              _0: initialState
            };
    } else {
      return {
              TAG: /* Update */0,
              _0: {
                processing: false,
                products: state.products
              }
            };
    }
  }
  switch (action.TAG | 0) {
    case /* ProductBulkAddRequested */0 :
        return {
                TAG: /* UpdateWithSideEffects */1,
                _0: {
                  processing: state.processing,
                  products: Belt_Array.concat(action._0, state.products)
                },
                _1: (function (self) {
                    Curry._1(self.dispatch, {
                          TAG: /* ProductRecipientStockAndIdBulkFetchRequested */4,
                          _0: recipientShopId
                        });
                  })
              };
    case /* ProductRemoveRequested */1 :
        var cku = action._0;
        return {
                TAG: /* Update */0,
                _0: {
                  processing: state.processing,
                  products: Belt_Array.keep(state.products, (function (product) {
                          return product.cku !== cku;
                        }))
                }
              };
    case /* ProductUpdateRequested */2 :
        var update = action._1;
        var cku$1 = action._0;
        return {
                TAG: /* Update */0,
                _0: {
                  processing: state.processing,
                  products: Belt_Array.map(state.products, (function (product) {
                          if (product.cku === cku$1) {
                            return Curry._1(update, product);
                          } else {
                            return product;
                          }
                        }))
                }
              };
    case /* ProductTransferredQuantityUpdateRequested */3 :
        var transferredQuantity = action._1;
        var cku$2 = action._0;
        return {
                TAG: /* Update */0,
                _0: {
                  processing: state.processing,
                  products: Belt_Array.map(state.products, (function (product) {
                          if (product.cku === cku$2) {
                            return {
                                    cku: product.cku,
                                    name: product.name,
                                    description: product.description,
                                    purchasePrice: product.purchasePrice,
                                    stockKeepingUnit: product.stockKeepingUnit,
                                    senderVariantId: product.senderVariantId,
                                    senderStockQuantity: product.senderStockQuantity,
                                    senderStockState: product.senderStockState,
                                    recipientVariantId: product.recipientVariantId,
                                    recipientStockQuantity: product.recipientStockQuantity,
                                    recipientStockState: product.recipientStockState,
                                    transferredQuantity: transferredQuantity
                                  };
                          } else {
                            return product;
                          }
                        }))
                }
              };
    case /* ProductRecipientStockAndIdBulkFetchRequested */4 :
        var shopId = action._0;
        return {
                TAG: /* UpdateWithSideEffects */1,
                _0: {
                  processing: true,
                  products: Belt_Array.map(state.products, (function (product) {
                          return {
                                  cku: product.cku,
                                  name: product.name,
                                  description: product.description,
                                  purchasePrice: product.purchasePrice,
                                  stockKeepingUnit: product.stockKeepingUnit,
                                  senderVariantId: product.senderVariantId,
                                  senderStockQuantity: product.senderStockQuantity,
                                  senderStockState: product.senderStockState,
                                  recipientVariantId: product.recipientVariantId,
                                  recipientStockQuantity: undefined,
                                  recipientStockState: product.recipientStockState,
                                  transferredQuantity: product.transferredQuantity
                                };
                        }))
                },
                _1: (function (self) {
                    var updatingRecipientStocksAndIds = Belt_Array.map(self.state.products, (function (product) {
                            return Future.mapOk(getVariantByCkuAndShopId(client, product.cku, shopId), undefined, (function (results) {
                                          if (results.length !== 1) {
                                            return ;
                                          }
                                          var result = results[0];
                                          Curry._1(self.dispatch, {
                                                TAG: /* ProductUpdateRequested */2,
                                                _0: product.cku,
                                                _1: (function (productToUpdate) {
                                                    return {
                                                            cku: productToUpdate.cku,
                                                            name: productToUpdate.name,
                                                            description: productToUpdate.description,
                                                            purchasePrice: productToUpdate.purchasePrice,
                                                            stockKeepingUnit: productToUpdate.stockKeepingUnit,
                                                            senderVariantId: productToUpdate.senderVariantId,
                                                            senderStockQuantity: productToUpdate.senderStockQuantity,
                                                            senderStockState: productToUpdate.senderStockState,
                                                            recipientVariantId: result.node.id,
                                                            recipientStockQuantity: result.node.stock.rawQuantity,
                                                            recipientStockState: result.node.stock.state,
                                                            transferredQuantity: productToUpdate.transferredQuantity
                                                          };
                                                  })
                                              });
                                        }));
                          }));
                    Future.get(Future.all(updatingRecipientStocksAndIds), (function (param) {
                            Curry._1(self.dispatch, /* ProductRecipientStockAndIdBulkFetchSucceeded */1);
                          }));
                  })
              };
    
  }
}

function use(param) {
  var client = ReasonMLCommunity__ApolloClient.React.useApolloClient(undefined);
  var match = Curry._1(StockTransferForm$Pos.useFormState, undefined);
  var status = match.status;
  var formDispatch = Curry._1(StockTransferForm$Pos.useFormDispatch, undefined);
  var recipientShopId = match.values.recipientShopId;
  var match$1 = ReactUpdateReducer$Pos.use((function (param, param$1) {
          return reducer(client, recipientShopId, param, param$1);
        }), initialState);
  var productsDispatch = match$1[1];
  var productsState = match$1[0];
  React.useEffect((function () {
          if (!productsState.processing && status !== /* Pristine */0) {
            Curry._1(formDispatch, {
                  TAG: /* FieldValueChanged */2,
                  _0: /* Products */2,
                  _1: (function (param) {
                      return Belt_Array.map(productsState.products, (function (product) {
                                    return {
                                            quantity: product.transferredQuantity,
                                            senderVariantId: product.senderVariantId,
                                            recipientVariantId: product.recipientVariantId
                                          };
                                  }));
                    })
                });
          }
          
        }), [productsState]);
  React.useEffect((function () {
          if (status === /* Pristine */0) {
            Curry._1(productsDispatch, /* ResetRequested */0);
          }
          
        }), [status]);
  return [
          productsState,
          productsDispatch
        ];
}

export {
  VariantsByCkuQuery ,
  getVariantByCkuAndShopId ,
  initialState ,
  reducer ,
  use ,
}
/* query Not a pure module */
