/* TypeScript file generated from Env.resi by genType. */
/* eslint-disable import/first */


// @ts-ignore: Implicit any on import
import * as EnvBS__Es6Import from './Env.bs';
const EnvBS: any = EnvBS__Es6Import;

export const sentryDsn: () => (null | undefined | string) = EnvBS.sentryDsn;

export const intercomAppID: () => string = EnvBS.intercomAppID;

export const logrocketAppID: () => string = EnvBS.logrocketAppID;

export const postHogAppKey: () => string = EnvBS.postHogAppKey;

export const context: () => "staging" | "dev" | "test" | "production" = EnvBS.context;
