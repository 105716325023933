// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";

var cavavinCorporateNameRegex = /^witradis$/i;

var cavavinMailDomainRegex = /^[a-zA-Z0-9._%+-]+@cavavin\.([a-z]{2})$/;

var winoMailDomainRegex = /^[a-zA-Z0-9._%+-]+@wino\.fr$/;

function hasCavavinPermission(username, shops) {
  var shopsCorporateNameOk = Belt_Array.every(shops, (function (shop) {
          return cavavinCorporateNameRegex.test(shop.corporateName);
        }));
  var emailOk = cavavinMailDomainRegex.test(username);
  var winoEmailOk = cavavinMailDomainRegex.test(username);
  if (shopsCorporateNameOk || emailOk) {
    return true;
  } else {
    return winoEmailOk;
  }
}

export {
  cavavinCorporateNameRegex ,
  cavavinMailDomainRegex ,
  winoMailDomainRegex ,
  hasCavavinPermission ,
}
/* No side effect */
