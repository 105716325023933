// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Text$Pos from "../../primitives/Text.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Colors$Pos from "../theme/Colors.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FontFaces$Pos from "../theme/FontFaces.bs.js";
import * as FontSizes$Pos from "../theme/FontSizes.bs.js";
import * as StyleSheet$Pos from "../../primitives/StyleSheet.bs.js";

var styles = StyleSheet$Pos.create({
      view: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Colors$Pos.neutralColor15, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 3, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "flexStart", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(8.0)), undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(4.0)), undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      text: Style$Pos.merge([
            Style$Pos.style(undefined, undefined, undefined, Colors$Pos.neutralColor100, undefined, FontSizes$Pos.xsmall, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(1.5)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
            FontFaces$Pos.libreFranklinRegularStyle
          ])
    });

function Tag(Props) {
  var children = Props.children;
  return React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(Text$Pos.make, Text$Pos.makeProps(children, Caml_option.some(Style$Pos.arrayStyle([styles.text])), undefined, undefined, undefined))), Caml_option.some(Style$Pos.arrayStyle([styles.view])), undefined, undefined, undefined));
}

var make = React.memo(Tag);

export {
  styles ,
  make ,
}
/* styles Not a pure module */
